@import 'cdn';

/* =Colors
----------------------------------------------- */
@yellow: #DF9300;
@gray: #666666;
@orange: #FF5C00;

@bgcolor: #EEE;

/* =Mixins
----------------------------------------------- */

.box_shadow (@color, @x:0px, @y:1px, @blur:5px) {
	// -moz-box-shadow: @x @y @blur @color;
	// -webkit-box-shadow: @x @y @blur @color;
	box-shadow: @x @y @blur @color;
}

.inner_box_shadow (@color, @x:0px, @y:1px, @blur:5px) {
	// -moz-box-shadow: inset @x @y @blur @color;
	// -webkit-box-shadow: inset @x @y @blur @color;
	box-shadow: inset @x @y @blur @color;
}

.transform (@params) {
	// -webkit-transform: @params;
	// -moz-transform: @params;
	// -ms-transform: @params;
	// -o-transform: @params;
	transform: @params;
}

.arrow_down(@width, @height, @color) {
	border-left: @height solid transparent; 
	border-top: @width solid @color;
	border-right: @height solid transparent;
	content: '';	
	position: absolute;
	transform: scale(1.001);	// slight scale to get antialiasing. 
}

.arrow_up(@width, @height, @color) {
	.arrow_down(@width, @height, @color);
	border-top: none;
	border-bottom: @width solid @color;
}

.smaller_media_items(@spacing: 3px, @size: 50px) {
	.sendage-media {
		margin: @spacing @spacing;
		.sendage-media-item {
			width: @size; 
			height: @size;
		}
	}
}

.small-button {
	button  { height: 16px; width: 16px; .box_shadow(#fff, 0px, 0px, 0px);}
	a       { height: 14px; width: 14px; .box_shadow(#fff, 0px, 0px, 0px);}
	span.ui-button-text { padding: 0; }
}


* { margin: 0; }

html { height: 100%; margin: 0 0; padding: 0 0; }

html,body {
	background-color: black;
}

html.print, body.print {
	background-color: transparent;
}

body {
	box-sizing: border-box;
	color: #141313;
	font-family: Segoe UI,Arial,sans-serif; /*Helvetica, Arial, sans-serif*/
	*font-family: Arial,sans-serif; 
	font-size: 12px;
	height: 100%;
	margin: 0;
	padding: 35px 0 0 0;
}

.video_container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
}
.video_container iframe,
.video_container object,
.video_container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#gbanner {
	text-align: center;
	.adsbygoogle {
		margin-bottom: 10px;
		margin-top: 5px;
	}
}

.gadmax { width: 320px; height: 50px; }
@media (min-width: 468px) { .gadmax { min-width:400px;max-width:970px;width:100%;height:90px } }

#header {
	padding-top: 40px;
	position: relative;

	&.dev {
		padding-top: 90px;
	}

	.logopane {
		height: 200px;
		margin: 0 auto;
		padding-top: 5px;
		position: relative;
		width: 396px;
		
		.boulders {
			background-image: url("@{cdn}img/sendage-boulders-130x130.png");
			height: 130px;
			margin-left: 138px;
			margin-top: 50px;
			position: absolute;
			width: 130px;
		}
		
		.banner {
			background-image: url("@{cdn}img/sendage-banner-396x128.png");
			height: 128px;
			margin: 105px auto 0;
			position: absolute;
			width: 396px;
			
			a.home-banner {
				display: block;
				height: 40px;
				left: 102px;
				position: absolute;
				top: 82px;
				width: 190px;
			}

		}
		
		.navigation {
			height: 160px;
			left: 50%;
			margin-left: -120px;
			position: absolute;
			width: 240px;
			
			a {
				background-image: none;
				display: block;
				position: absolute;	
				text-indent: -99999px;
			}
				
			&.loggedout {
				background-image: url("@{cdn}img/sendage-navigation-loggedout.png");
				
				a {
					
					&.home {
						background-position: 0px -253px;
						height: 60px;
						left: 0px;
						top: 93px;
						width: 20px;
					}
					&.search {
						background-position: -10px -180px;
						height: 61px;
						left: 10px;
						top: 20px;
						width: 56px;
					}
					&.create {
						background-position: -77px -160px;
						height: 25px;
						left: 77px;
						top: 0px;
						width: 92px;
					}
					&.about {
						background-position: -178px -184px;
						height: 52px;
						left: 178px;
						top: 24px;
						width: 50px;
					}
					&.user {
						background-position: -222px -250px;
						height: 62px;
						left: 222px;
						top: 90px;
						width: 18px;
					}
					
					&:hover {
						background-image: url("@{cdn}img/sendage-navigation-loggedout.png");
					}
				}
			}
			
			&.loggedin {
				background-image: url("@{cdn}img/sendage-navigation-loggedin.png");
				
				a {
					&.home {
						background-position: 0px -258px;
						height: 56px;
						left: 0px;
						top: 98px;
						width: 20px;
					}
					&.search {
						background-position: -9px -187px;
						height: 60px;
						left: 9px;
						top: 27px;
						width: 48px;
					}
					&.create {
						background-position: -66px -160px;
						height: 30px;
						left: 66px;
						top: 0px;
						width: 84px;
					}
					&.about {
						background-position: -161px -174px;
						height: 43px;
						left: 161px;
						top: 14px;
						width: 53px;
					}
					&.user {
						background-position: -213px -226px;
						height: 88px;
						left: 213px;
						top: 66px;
						width: 28px;
					}
					&:hover {
						background-image: url("@{cdn}img/sendage-navigation-loggedin.png");
					}
				}
			}		
		}
	}
	
	.navpane {
		display: none;
		height: 40px;
		margin: 0 auto;
		position: relative;
		text-align: center;
		width: 940px;
	
		a { text-decoration: none; }
		.col { margin-right: 133px; }
		.colRight { margin-left: 0px; }
		h3 {  }
	}
	
	.social {
		bottom: 5px;
		position: absolute;
		right: 0;
		a {
			color: @yellow;
			display: inline-block;
			font-size: 2.25em;
			margin-left: 3px;
			&:hover {
				//color: darken(@yellow, 10%);
			}
			
			outline: none;
			&:active {
				outline: none;
			}
		}
	}
	
}


/* below the header. Holds the main content - centered */
#centerpane {
	background-color: @bgcolor;
	border: 2px solid @yellow;
	border-radius: 5px;
	margin: 0 auto;
	min-height: 250px;
	padding: 40px 10px 10px 10px;
	width: 940px;
}
#centerpane2 {
	background-color: @bgcolor;
	border: 2px solid @yellow;
	border-radius: 5px;
	min-height: 250px;
	padding: 40px 0 10px 0; 
}

#footer {
	border-radius: 5px;
	margin: 0 auto;
	padding: 0px 10px;
	width: 940px;

	.links {
		padding-top:15px;
		text-align:center;
	}
	.showdesktop {
		//display: none;
	}

}

.footer-link  { 
//	margin: 0 5px; 
//	padding: 0 5px;
	
	a {
		padding: 0 6px; 
	}
	
	&:after {
		content: "|";
	}
	
	&.copyright:after,
	&:last-child:after {
		content: '';
	}
}


#user_panel {
	background-color: @bgcolor;
	border-bottom: 2px solid @yellow;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border-left: 2px solid @yellow;
	border-right: 2px solid @yellow;
	margin-bottom: 10px;
	margin-left: -482px;
	padding: 5px 0;
	position: fixed;
	top: 0;
	left: 50%;	
	z-index: 999;

	.box_shadow(#555);
	
	.user {
		a {
			line-height: 24px;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	
	&.dev {
		top: 45px;
	}
}

.welcome_banner {
	background-color: @bgcolor;
	border: 2px solid @yellow;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-top: none;

	font-size: 18px;
	margin: 0 auto;
	padding: 10px;
	text-align: center;
	width: 940px; 
}


#stickyfooter-wrapper {
	background: url("@{cdn}img/sendage-background-2200x1130.png") no-repeat center -15px #191917;
	height: auto !important;
	height: 100%;
	margin: 0 auto -40px;
	min-height: 100%;
}

#stickyfooter-push, #footer {
	height: 40px;
}

.withSidebarLeft {
	border-right: 1px solid #CCC;
	float: left;
	margin-right: 15px;
	min-height: 250px;
	padding-right: 9px;
	width: 615px;
}	

.withSidebarRight {
	float: left;
	margin-right: 0px;
	width: 300px;
}


.biginfo {
	color: @gray;
	font-size: 18px;
	line-height: 24px;
}

#welcome_message li,
ol.listed > li,
ul.listed > li { 
	margin-left: 30px;
	margin-top: 8px;
}

#welcome_message ul li,
ul.listed > li { list-style-type: disc; }
#welcome_message ol li,
ol.listed > li { list-style-type: decimal; }

#welcome_message {
	margin-bottom: 20px;
	.image {
		border: 2px solid @yellow;
		border-radius: 10px;
		margin: 10px 0 20px 20px;
	}
}

#welcome_facebook_feed {
	background-image: url("@{cdn}img/welcome/facebook_feed.png");
	height: 306px;
	width: 506px;
}

#welcome_8a {
	background-image: url("@{cdn}img/welcome/add_another_send_8a.png");
	height: 296px;
	width: 505px;	
}

#welcome_send {
	background-image: url("@{cdn}img/welcome/send_climb.png");
	height: 572px;
	width: 422px;	
}

.welcome-banner .image {
	background-image: url("@{cdn}img/welcome_banner_75.png");
	height: 75px;
	margin: 0 auto;
}

.welcome-banner {
	h1 { font-size: 22px; padding-top: 0; }
	p { font-size: 14px; text-align: justify; }
	li { display: none; }
	
	&.intro li.intro { display: block; }
	&.plan {
		li.plan { display: block; }
		.image { width: 100px; }
	}
	&.list {
		li.list { display: block; }
		.image { background-position: -104px top; width: 65px; }
	}
	&.accomplish {
		li.accomplish { display: block; }
		.image { background-position: -170px top; width: 75px; }
	}
	&.share {
		li.share { display: block; }
		.image { background-position: -245px top; width: 83px; }
	}
}
.welcome-banner-dots-container {
	height: 10px;
	margin: 0 auto 20px auto;
}
.welcome-banner-dots {
	margin: 0 auto;
	text-align: center;
	width: 100px;

	a {
		border: 1px solid @yellow;
		border-radius: 4px;
		display: inline-block;
		height: 8px; 
		margin: 0 3px;
		width: 8px;
		&.active { background: @yellow; }
	}
}


a {
	color: inherit;
	text-decoration: underline;
}


li { margin: 3px 0; }

strong {
	color: @yellow;
	font-weight: bold;
	
	&.orange {
		color: @orange;
	}
}	

img, map { border: none; }

ul { padding: 0; }
li { list-style: none; } 

h1,h2,h3,h4 { 
	color: @yellow;
	font-weight: bold;
	padding: 15px 0 5px 0; 

	&.top { padding-top: 0px; } 
}

h1 { font-size: 20px; }
h2 { font-size: 16px; }
h3 { font-size: 16px; }
h4 { font-size: 14px; }

a.highlight-sel, .highlight { color: @yellow; }
a.highlight-sel { text-decoration: none; cursor: default; }

.row { margin: 5px 0; }
.col { float: left; margin-right: 10px; &.last {margin-right:0px;}}
.colRight { float: right; margin-left: 10px; &.last {margin-left:0px;}}
.colLast { float: left; margin-right: 0px; } 
.colRightRight { float: right; margin-right: 10px; } 
.colbreak {	clear: both; }
.clear {	clear: both; }

.clearfix {
    *zoom: 1;

	&:before,
	&:after {
	    content: " "; /* 1 */
	    display: table; /* 2 */
	}
	&:after {
	    clear: both;
	}

}



input,
select,
textarea,
.bordered {
	border: 1px solid @yellow;
	border-radius: 5px;
}

input, textarea {
	padding: 5px;
}
select {
	height: 29px;	/* Without the height set, Chrome fails. It doesn't seem to understand padding */
	padding: 5px 3px;
}

input.nostyle {
	border: none;
	padding: 0;
}

.profilepic {
	/*border: 1px solid #999;*/
	border-radius: 3px;
	display: block;
	height: 50px;
	overflow: hidden;
	width: 50px;
	
	
	&:hover {
		/*border: 1px solid @yellow;*/
	}
	
	&.small {
		height: 24px;
		width: 24px;
	}
	
	&.medium {
		height: 40px;
		width: 40px;
	}

	&.inprogress {	
		background: url("@{cdn}img/wait_spinner.gif") center center no-repeat;
	}

	
	img {
		height: inherit;
		width: inherit;
	}
}


#climbs {
	padding: 5px 20px 5px 5px;
	/*width: 274px;*/	/* 300 - 5 - 25 - 1 */
}

.empty-sendlist {
	background-color: lighten(@gray, 50%);
	border-radius: 5px;
	color: @gray;
	line-height: 20px;
	padding: 10px;
	text-align: center;
	margin-top: 23px; 
	
	.inner_box_shadow(#666);
	
	label {
		display: block;
		font-weight: bold;
	}
}

#sendlistContainer {
	height: 238px;	/* 250 - 5 - 5 - 2 */
	overflow: auto;
	padding: 5px;
	width: 288px;	/* 300 - 5 - 5 - 2*/
}

#sendlist-search { width: 230px; }


.sendlist-delete-confirm {
	margin-right: 3px;
	position:absolute;
	right:0px;
	text-align:right;
	top:2px;
	width:100px;
}

a.sendlist-edit-name {
	font-size: 14px;
	font-weight: normal;
}

#sendlist_info {

	width: 400px;

	* {
		box-sizing: border-box;
	}
	.viewing-editing-sendlist & {
		.title, .description {
			display: none;
		}
		.form {
			display: block;
		}
	}

	.form {
		display: none;
		margin-bottom: 5px;
		text-align: right;

		input[type=text], textarea { 
			display: inline-block;
			margin-bottom: 5px;
			width: 100%; 
		}
		input[type=submit] { 
			line-height: 18px; 
			padding: 4px 12px; 
			text-align: right;
		}

		input[type=text] {
			position: relative;

			&.error {
				border-color: red;

				+ .error-message {
					display: inline-block;
				}
			}
		}

		.name {
			position: relative;
		}

		.error-message {
			color: red;
			display: none;
			padding: 6px;
			position: absolute;	
			right: 0;
			top: 0;
		}
	}
}
#sendlist_edit_links {
	color: @gray;
	text-align: center;
	width: 123px;

	.ui-button {
		width: 100%;
	}

	.edit-list, .save-list {
		margin-bottom: 8px;
	}

	.save-list {
		display: none;
	}

	.share-list {
		display: none;
	}

	.viewing-editing-sendlist & {
		.edit-list {
			display: none;
		}
		.save-list {
			display: inline-block;
		}
	}

	.pending-sendlist.viewing-editing-sendlist & {
		.delete-list {
			display: none;
		}
	}
	.pending-sendlist.viewing-editing-sendlist.facebook-user-logged-in & {
		.share-list {
			display: block;
		}
	}
}


.wait-spinner-container {
	display: inline;
	margin-left: 5px;
	position: relative;
	top: 3px;
}

.wait-icon,
.error-icon {
	display: inline-block;
	height: 16px;
	margin-left: 5px;
	position: relative;
	width: 16px;
}

.wait-icon { background-image: url("@{cdn}img/wait_spinner.gif"); }
.error-icon { background-image: url("@{cdn}img/exclamation-16x16.png"); }


p,
section,
.prompt { color: @gray; }

.indented { padding-left: 5px; }

#action-buttons {
	margin: 10px 0;
}

#action-buttons button {
	margin-left: 5px;
}

ul.home-feed {
	border-top: 1px solid #CCC;
}
ul.home-feed li {
	border-bottom: 1px solid #CCC;
	padding: 4px 0;
}


.social_signin_button {
	background-image: url("@{cdn}img/social_signin.png");
	cursor: pointer;
	display: block;
	height: 29px;
	margin: 20px auto;
	width: 162px;	
	
	&.google 	{ background-position: left 29px*(7-6); }
	&.facebook 	{ background-position: left 29px*(7-2); }
}

.signin_options {
	text-align: center;
	
	.faq {
		border-top: 1px solid #ccc;
		margin: 30px 0;
		text-align: left;
		
	}
	.notes {
		color: #888;
		padding: 20px;
	}
}

/********** new climb dialog ***************/

#newclimbdialog {
	input[type=text] { width: 215px; }
	.create-grade { width: 80px }
	h3 { padding-top: 5px; }
}

#newclimbform { margin-top: 15px; }

/*********************************************/

#advertising {
	p {
		margin: 1em 0;
	}
}

#advertising_payment {
	p {
		margin: 0.75em 0;
	}
	
	li {
		color: @gray;
		list-style: disc;
	}
	
	.plan {
		display: none;
	}
	
	#planpicker {
		margin-right: 30px;
	}
	
	#flashMessage {
		border: 1px solid red;
		color: red;
		display: inline-block;
		font-size: 1.6em;
		margin: 10px;
		padding: 10px;
	}
	
	input[type=file] {
		border: none;
		padding: 2px;
	}
	
	input[type=text] {
		width: 200px;
	}
	
	label {
		display: inline-block;
		text-align: right;
		margin-right: 10px;
		width: 60px;
	}
	
	.checkout {
		margin-bottom: 30px;
		margin-top: 30px;
		
		.disabled {
			margin-bottom: -3px; // not sure why this button is diff from enabled button.
		}
		
		.terms {
			color: @gray;
			margin-left: -16px;
		}
	}
}

/* SEARCH */
#searchResults {
	margin-top: 10px;
	margin-bottom: 20px;

	ul {
		border-top: 1px solid #888;
		
		.ui-button-text { padding: 0; }
	}
	ul.empty { border: none; }

	li {
		border-bottom: 1px solid #888;
		padding: 3px 0;
		
		div.type {
			min-height: 20px;
			width: 20px;
		}

	}

	.climb { width: 165px; } 
	.grade { width: 40px; } 
	.sends { width: 25px; text-align: right; }

	button.ui-button-icon-only { height: 16px; width: 16px; }
	.paging button.ui-button-icon-only { width: 24px; }
	.paging .ui-button-text { padding: 0; }
}


select.search-grade { width: 70px; }

form.search { 
	margin-bottom: 10px; 
	div.prompt { margin-left: 5px; }
	input[name=q] {
//		width:520px;
	}
}
#user_panel form.search {
	margin-bottom: 0;
	input[name=q] {
		width: 250px;
	}
}


#qq_upload_button {	
	width: 226px;
}

#sendlist_media_uploader button,
#media_uploader button {
	line-height: 16px;
	padding:4px 12px 4px 35px;
	.ui-button-icon-primary {
		left: 12px;
	}
	span {
		/*display: inline; // not sure why i'm removing this. It's to fix the header search button, but could break something else??? */
		font-size: inherit;
		line-height: inherit;
		padding: 0;
	}	
}

input.with-button {
	border-right: none; 
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	line-height: normal;
}

button.with-input,
input.with-input {
	line-height: normal;
	padding:5px 12px;
	border-color: @yellow;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	
	.ui-button-text,
	span {
		/*display: inline; // not sure why i'm removing this. It's to fix the header search button, but could break something else??? */
		font-size: inherit;
		line-height: inherit;
		padding: 0;
	}
}

ul.search-results { margin-left: 20px; }
ul.search-results li { margin-bottom: 12px; }

#sendlist_media_uploader,
#media_uploader {
	margin-top:20px;
	
	&.top {
		margin-top: 0px;
	}
}
#sendlist_media_upload_preview {
	margin-top: 10px;
}
#sendlist_media_upload_preview .sendage-media-item {
	cursor: default;
}

/* SENDLIST */
ul.ui-sendlist-border {
	border-top: 1px solid #888;
}

ul.ui-sendlist {
	padding: 3px;

	li {
		border-bottom: 1px solid #888;
		padding: 2px 0;
		
		div {
			&.info {
				width: 170px;
			}
			
			&.grade {
				width: 45px; 
			}
			&.comments {
				width: 210px;
				.prompt;
			}

			&.sendlist-action-buttons {
				//margin-top: -2px;
				//.small-button;
				color: @yellow;
				font-size: 16px;
				text-align: center;
				
				i.fa:hover {
					color: @orange;
				}
			}
			
			&.rating {
				margin-left: 0px;
			}			
		}
		
		.trophy {
			font-size: 16px;
			text-align: center;
			min-height: 5px;
			width: 32px;
		}
		
		.ui-sendlist-climb-trophy.editable {
			sup {
				display: block;
				margin-top: -2px;
			}
		}
		
		.ui-button {
			display: block;
			margin-bottom: 2px;
		}
		
		.remove_option {
			display:none;
			height:20px;
			position:relative;
		}
		
	}

}


.ui-sendlist-climb-trophy.editable {
	color: #888;
	cursor: pointer;
	text-align: center;
}

#home_feed {
	ul {
		>li {
			.instagram-user {
				img {
					width: 50px;
				}
			}
			.instagram {
				img {
					max-width: 100%;
					width: auto;
				}
				p {
					margin: 1em 0;
					
					a {
						color: @yellow;
						text-decoration: none;
						
					}
				}
			}
			
		}
	}
}

.feed-item { width: 560px; }
.feed-send-trophy { text-align:center; width: 50px; font-size: 25px; }
.feed-send-description { margin-top: 5px; width: 490px; }
.feed-time { margin-top: 5px; }
.feed-list-description { margin-top:5px; width: 560px; }
.ui-sendlist-climb-label { font-weight: bold; }

.ui-sendlist-climb-location {
	color: @yellow;
	display: inline-block;
	font-size: 11px;
	font-style: italic;
	margin-bottom: 2px;
}

#sendage-dialog {
	.trophy-selector {
		margin: 0 auto 15px; 
		text-align: center;
		
		.trophy-count {
			display: inline-block;
			margin: 0 5px;
			width: 52px;
		}
		
		.trophy, .repeat, .project {
			font-size: 26px;		
		}
	}
	
	.rating-container {
		width: 90px;
	}
}

#sendage-dialog-datepicker {
	width: 240px;
}

.sendage-dialog-beta,
.sendage-dialog-comment {
	height: 50px;
	width: 360px;
}	

.trophies {
	cursor: pointer;
	border: 1px solid transparent;
	.box_shadow(#888);
	
	&.bordered {
		border: 1px solid @yellow;
	}
	
	&.selected {
		border: 3px solid @yellow;
		padding: 3px;
	}
	
	&.hover {
		border: 1px solid @yellow;
		padding: 5px;
	}
	
}

span.error,
div.error, 
#rating_error { color: red; }

.error400 {
	.suggestions {
		margin-top: 20px;
	}
	ul {

		li {
			margin-bottom: 5px;
		}
	}
}

.setting { 
	border-bottom: 1px solid #CCC;
	margin-top: 15px; 
	padding-bottom: 10px;
	
	&.noborder {
		border: none;
	}
	
	.sponsor-list {
		margin-top: 5px; 
		width: 805px;
		.item {
			margin-top: 2px; 
			label {
				cursor: pointer;
				input {
					display: inline-block;
					margin-top: 5px;
					vertical-align: top;
				}
				display: inline-block;
				padding: 0 5px 5px 0;
			}
		}
	}
	
	.other-sponsors {
		margin-top: 10px;
	}
}
.settings-label		{ width: 125px; }
.settings-desc		{ width: 485px; }
.settings-value	{
	text-align: right; 
	width: 280px; 
	input[type=text] {	width: 270px; }
	textarea { width: 270px; height: 60px; }
}

sup, sub { font-size: 11px; }

sup.super { vertical-align: super; }
sub.sub { vertical-align: sub; }

ul.active-areas li,
ul.popular-climbs li {
	color: @yellow;
	list-style: none;
	margin-top:2px;
	padding-left:10px;
}

ul.listed-sendlists li {
	margin-top:8px;	
}

/* Used on Area page and on Climb page for dialog boxes */
.edit-info-form {
	div.input { margin-top: 10px; }
	select,
	textarea,
	input[type=text] { 
		box-sizing: border-box;
		width: 100%; 
	}
	textarea { height: 90px; }

	label {
		color: @yellow;
		display: block;
		font-weight: bold;	
		padding-left: 5px;
	}
}

#edit_area_form {
	.inputs,.map {
		box-sizing: border-box;
		margin: 0;
	}
	.inputs {
		padding-right: 10px;
		width: 40%;
	}
	
	.map {
		padding-left: 10px;
		width: 60%;
	}

	.latlon {
		margin-top: 13px;
	}
}


.inset_panel {
	background-color: lighten(@gray, 50%);
	border-radius: 5px;
	padding: 15px 10px; 
	margin: 0 0 15px 0px;
	
	h1 { padding-top: 0px; padding-bottom: 10px; }
	
	.inner_box_shadow(#666);
}

.sidebar-ad-container {
	height: 250px;
	margin-bottom: 15px;
	overflow: hidden;
	position: relative;
	width: 300px;
	
	.sidebar-ad {
		cursor: pointer;
		display: block;
		height: 250px;
		left: 0;
		width: 300px;
		opacity: 0;
		position: absolute;
		top: 0;
		transition: opacity 0.5s;
		z-index: 1;
		
		&.active {
			opacity: 1;
			z-index: 2;
		}
	}
}

.sidebar_panel {
	background-color: darken(@bgcolor, 2%);
	border-radius: 5px;
	display: block;
	padding: 15px 10px; 
	margin: 0 0 15px 0px;
	width: 280px;
	
	h1 { padding-top: 0px; padding-bottom: 10px; }
	
	.inner_box_shadow(#666);
	
	&.anchored_image {
		cursor: pointer;
		padding-bottom: 0;
		padding-top: 0;
	}

	&.centered {
		text-align: center;
	}
	
	.sidebar-ad {
		height: 250px;
		width: 300px;
	}
}

.sidebar_imagead {
	display: block;
	margin: 15px 0;
}

.adsense {
	margin: 15px 0;
	
	>div {
		color: @gray;
		text-align: right;
	}
}

.media_panel {
	background-color: lighten(@gray, 50%);
	border-radius: 5px;
	margin-top: 10px; 
	padding: 10px; 
	
	#media_uploader { margin-top: 0px; }
	
	.inner_box_shadow(#666);
}

.pages_home.grid_4 {
	.smaller_media_items;
}

.climbs_view {
	
	h3 {
		margin-top: 20px;
		padding-top: 0;
		
	}
	
	.send_info {
		text-align: center;
		h3 { padding-bottom: 0px; padding-top: 0px; }
		.trophies {
			cursor: default;
		}
	}
	
	.climb-title {
		.areas {
			color: @gray;
		}
	}
	
	.climb-info {
		//border-bottom: 1px solid #CCC;
		//min-height: 65px;
		
		.climb-title {
			float: left;
		}
		.climb-meta {
			clear: both;
			.prompt;
		}
		.climb-rating {
			float: right;
			padding-top: 3px;
		}
	}
	ul.climb-beta {
		li {
			margin-top: 8px;
			.avatar {
				
			}	
			.comments {
				width: 570px;
			}		
		}
	}
	ul.recent-sendage {
		li {
			margin-top: 8px;
			
			.trophy {
				text-align: center;
				width: 40px;
			}
			.title {
				width: 105px;
				
				.fa-star-rating {
					font-size: 12px;
				}
			}
			.comments {
				.prompt;
				width: 390px;
			}
			
			.trophy.fa-stack {
				font-size: 16px;
			}
		}
		
	}
	
	.logged_in_buttons {
		display: none;
		margin-top: 10px; 
		
		button, a {
			display: inline-block;
			margin-bottom: 5px;
			margin-top: 20px; 
			
			&.major {
				text-align: left;
				width: 200px;
				margin-top: 0px;
			}
		}
	}
	
	.climb_media_panel {
		display: none;
	}
	
	.smaller_media_items;
	
	.sidebar_panel {
		&.climb_overview_image {
			background-position: center center;
			background-size: cover;
			height: 196px;
		}
	}
}

.user_climbs_view {
	.title {
		width: 440px;
	}
	.comments {
		margin-left: 60px;
	}
	
	.repeats {
		color: @gray;
		width: 100%;

		th {
			color: @orange;
		}

		th,		
		td {
			padding: 2px;
		}
		
		.comments {
			margin: 0;
		}

		.cnt {
			text-align: center;
		}
		
		.remove {
			text-align: right;
		}
	}
}

.editable-content {
	box-sizing: border-box;
	position: relative;
	
	form,
	textarea {
		box-sizing: border-box;
		height: 100%;
		width: 100%;
	}
	
	textarea {
		padding-right: 75px;
	}
	
	button,input[type=submit] {
		background-color: @yellow;
		border-color: @yellow;
		border-bottom-left-radius: 0;
		border-top-right-radius: 0;
		bottom: 0;
		box-shadow: none;
		color: white;
		cursor: pointer;
		margin-right: 0;
		position: absolute;
		right: 0;
	}
}

.climbs_view .climb-info,
.areas_view {
	>.description {
		border-bottom: 1px solid #ccc;
		margin: 10px 0 20px;
		padding-bottom: 10px;

		h4 {
			padding: 0 0 2px;
			margin-top: 10px;
		}

		p {
			margin: 5px 0;
		}
		
		.edit-content,
		.under-review {
			color: @gray;
			float: right;
			font-size: 12px;
			font-style: italic;
			font-weight: normal;
		}
		
		.editable-content {
			display: none;
			height: 70px;
			margin: 0 0 20px;
			
			&.active {
				display: block;
			}
		}
	}
	
}

.area-tree {
	>ul {
		>li {
			>a {
				color: @yellow;
				display: inline-block;
				font-weight: bold;
				margin-top: 20px;
			}
			
			ul {
				padding-left: 20px;
			}
		}
	}
}

.area-tree-continued {
	ul {
		margin-top: 40px;
		padding-left: 20px;
	}
}

.areas_view {
	
	.smaller_media_items;

	.title {
		width: 445px;
	}
	
	.area_parent {
		color: @gray;
		margin-bottom: 10px;
	}
	
	.area_children {
		color: darken(@gray, 15%);
		margin: 20px 0 10px 0;
		
		box-sizing: border-box;
		margin: 0 -10px;
		h4 {
			padding-left: 10px;
		}
		.group {
			box-sizing: border-box;
			float: left;
			padding: 0 10px;
			width: 33.3333%;
			
			label {
				display: inline-block;
				font-weight: bold;
				padding-bottom: 4px;
			}
			
			div {
				margin-bottom: 4px;
				
				&.weight-5 {
					//opacity: 1;
					font-weight: bold;
				}
				&.weight-4 {
					color: @gray;
					font-weight: bold;
				}
				&.weight-3 {
					color: lighten(@gray, 5%);
					//opacity: 0.85;
				}
				&.weight-2 {
					color: lighten(@gray, 15%);
					//opacity: 0.75;
				}
				&.weight-1, 
				&.weight-0 {
					color: lighten(@gray, 20%);
					//opacity: 0.666;
				}
			}
		}
		
	}

	.logged_in_buttons {
		margin-top: 20px; 
		
		button, a {
			display: inline-block;
			margin-bottom: 5px;
			text-align: left;
			width: 230px;
			
			&.major {
				text-align: left;
				width: 200px;
				margin-top: 0px;
			}
		}
	}
	

}

.sendlist_create {

	#sendlist_name, 
	#sendlist_description { 
		// width: 445px; 
		box-sizing: border-box;
		width: 100%;
	}
	#sendlist-search { width: 240px; }
	
	.climb_search {
	}
	
	.fixed-filters {
		width: 160px; 
	}

	.filters {
		height: 25px; 
		margin-top:5px;
	}


	.form.prompt { margin-top: 10px; padding-left: 6px; } 
	
	.list_details { 
		margin-bottom: 20px; 
		
		.error {
			margin: 10px 5px 0 0; 
		}
	}

	.list_climbs {
		border-radius: 5px;
		min-height: 530px;
		//height: 550px;
		//overflow: auto;
		.inner_box_shadow(#AAA, 0, 0, 10px);
		
		.empty-list-message {
			padding: 15px; 
			text-align: center;
		}
	}

	.climb_count {
		color: @gray;
		text-align: right;
		line-height: 28px;
	}
	ul {
	
		&.ui-sendlist-border {
			border-top: none;
		}
	
		&.ui-sendlist {
			li {
				padding-left: 5px;
						
				.ui-sendlist-makecomment,
				div.comments,
				div.rating { display: none; }
				div.info { width: 300px; }
				div.grade { width: 40px; }
				div.sendlist-action-buttons { margin-right: 10px; }
			}
			
		}
	}
}


/* override star rating disable */
#sendage-dialog .rating-cancel { display: none !important; }

/* override jquery UI */

.ui-datepicker {
	border: 1px solid @yellow;
	display: none;
	position: absolute;
	width: 205px;
}

.ui-widget-content a {
    color: inherit;
}
.ui-button-text {font-size:14px;}
table td { padding-right: 10px; }
td.right { text-align: right; }
.ui-button {
	.box_shadow(#666, 1px, 1px, 2px);
	
	&.with-input {
		box-shadow: none;
		//.box_shadow(#fff, 0,0,0);
	}
}

.ui-autocomplete {
	*height: 200px;
	max-height: 200px;
	overflow: auto;
	// don't force 2 !important for z-index. ruins dialogs
	//z-index: 2; 
	
	.ui-menu-item {
		.climb {
			color: black;
		}
		.grade,
		.num-sends {
			color: @gray;
		}
		.area {
			color: @gray;
			font-size: 11px;
			font-style: italic;
			
		}
		
	}
	
	.ui-state-hover {
		background: none;
		background-color: fadeout(lighten(@yellow, 30%), 30%);
		border: 1px solid transparent;
		color: black;
	}
}

.ui-slider .ui-slider-handle {
	z-index: 1;
}

#qq_upload_button .ui-button {
	.box_shadow(#fff, 0,0,0);
}

.ui-state-default.major, .ui-widget-content .ui-state-default.major, .ui-widget-header .ui-state-default.major {
	@majorColor: #FF5C00;

	border: 1px solid darken(@majorColor, 20%);
	background: @majorColor;
	
	&.ui-state-hover {
		border: 1px solid @majorColor;
		background: lighten(@majorColor, 10%);
	}
	
	&.ui-state-active {
		border: 1px solid @majorColor;
		background: darken(@majorColor, 10%);
	}
}

/* Override Disqus */
#disqus_thread {color: @gray;}
#disqus_thread h1, #disqus_thread h2, #disqus_thread h3, #disqus_thread h4 { padding: 0; } 
/*#dsq-subscribe,*/
#dsq-global-toolbar { display: none !important; } 
#dsq-footer { margin: 5px 0 0 0 !important;}
#dsq-sort-by { float: none !important; }
#dsq-content h3,
#dsq-reply h3 { visibility: hidden; margin: 0 !important; }
#disqus_thread #dsq-content .dsq-pagination { padding: 5px; margin: 5px 0; }



/* Users Page */

.trophy-large, 
.trophy-small {
	background: url("@{cdn}img/sendage-trophy-sprite-x3.png") no-repeat;
	display: inline-block;
}
.trophy-fatype(@x, @y) {
	background-position: @x @y;
	&.fa { background-position: @x @y - 93px; }
}

.trophy-large {
	height: 40px;
	width: 32px;
	
	&.white { background-position: 0 0; }
	&.redpoint,&.bronze { .trophy-fatype(-32px, 0px); }
	&.flash,&.silver { .trophy-fatype(-64px, 0px); }
	&.onsight,&.gold { .trophy-fatype(-96px, 0px); }
	
}


.trophy-small {
	height: 30px;
	width: 24px;	
	
	&.project,&.white { background-position: 0 -41px; }
	&.redpoint,&.bronze { .trophy-fatype(-24px, -41px); }
	&.flash,&.silver { .trophy-fatype(-48px, -41px); }
	&.onsight,&.gold { .trophy-fatype(-72px, -41px); }
}

.trophy-show { 
	margin: 0 auto 10px auto;
	width: 248px; 
	
	.trophy {
		font-size: 30px;
	}
}

.trophy-count {
	background-color: #EEE;
	padding: 5px;
	text-align: center;
	width: 64px;
	h1 { padding: 0px; }
	
	.trophy.fa-stack {
		font-size: 30px;
	}
}


.iesucks {
	position:fixed; 
	bottom:0px; 
	height: 115px; 
	background-color: white; 
	width:100%; 
	text-align:center; 
	padding: 10px; 
	border-top: 
	5px solid red;
}

.js-required {
	border: 5px solid red;
	font-size: 20px;
	padding: 20px;
	text-align:center;
	
}

.dev-banner {
	background-color: white;
	border-bottom: 5px solid red;
	border-top: 5px solid red;
	font-size: 20px;
	padding: 5px;
	position: fixed;
	text-align:center;
	width: 100%;
	z-index: 998;	
}

.profile-info {
	label {
		.prompt;
		display: inline-table;
		line-height: 20px;
		padding-right: 3px;
		vertical-align: middle;
		
		&.header {
			line-height: 28px;
		}
	}
}

.profile-bio {
	.prompt;
	margin-top: 5px;
	padding-left: 59px;
	
	&.loggedin {
		width: 365px;
	}
}

.user-links  {
	a.protocol-anchor {
		//color: @yellow;
		font-size: 20px;
		outline: none;
		
		.fa-rss-square {
			color: @orange;
		}
		
		.fa-facebook-square {
			color: #3D5595;
		}
		.fa-twitter-square {
			color: #1BB7EA;
		}
		.fa-instagram {
			color: #FF295A;
		}
		
	}
}

.profile-icon {
	background: url("@{cdn}img/profile_icons_20.png") center center no-repeat;
	display: inline-table;
	height: 20px;
	margin-right: 3px;
	vertical-align: middle;
	width: 20px;
	
	&.sponsor {
		background: url("@{cdn}img/sponsor_icons_20.png") center center no-repeat;
	}
	
	&.wide { width: 40px; }
	
	.pos(@x:1, @y:1) {
		background-position: (@x - 1)*-20px (@y - 1)*-20px;
	}
	
	&.blog		{ .pos(1,1); }
	&.twitter	{ .pos(1,2); }
	&.facebook	{ .pos(1,3); }

	&.pos1x1 	{ .pos(1, 1); }
	&.pos1x2 	{ .pos(1, 2); }
	&.pos1x3 	{ .pos(1, 3); }
	&.pos1x4 	{ .pos(1, 4); }
	&.pos1x5 	{ .pos(1, 5); }
	&.pos1x6 	{ .pos(1, 6); }
	&.pos1x7 	{ .pos(1, 7); }
	&.pos1x8 	{ .pos(1, 8); }
	&.pos1x9 	{ .pos(1, 9); }
	&.pos1x10 	{ .pos(1,10); }
	&.pos2x1 	{ .pos(2, 1); }
	&.pos2x2 	{ .pos(2, 2); }
	&.pos2x3 	{ .pos(2, 3); }
	&.pos2x4 	{ .pos(2, 4); }
	&.pos2x5 	{ .pos(2, 5); }
	&.pos2x6 	{ .pos(2, 6); }
	&.pos2x7 	{ .pos(2, 7); }
	&.pos2x8 	{ .pos(2, 8); }
	&.pos2x9 	{ .pos(2, 9); }
	&.pos2x10 	{ .pos(2,10); }
	&.pos3x1 	{ .pos(3, 1); }
	&.pos3x2 	{ .pos(3, 2); }
	&.pos3x3 	{ .pos(3, 3); }
	&.pos3x4 	{ .pos(3, 4); }
	&.pos3x5 	{ .pos(3, 5); }
	&.pos3x6 	{ .pos(3, 6); }
	&.pos3x7 	{ .pos(3, 7); }
	&.pos3x8 	{ .pos(3, 8); }
	&.pos3x9 	{ .pos(3, 9); }
	&.pos3x10 	{ .pos(3,10); }
	&.pos4x1 	{ .pos(4, 1); }
	&.pos4x2 	{ .pos(4, 2); }
	&.pos4x3 	{ .pos(4, 3); }
	&.pos4x4 	{ .pos(4, 4); }
	&.pos4x5 	{ .pos(4, 5); }
	&.pos4x6 	{ .pos(4, 6); }
	&.pos4x7 	{ .pos(4, 7); }
	&.pos4x8 	{ .pos(4, 8); }
	&.pos4x9 	{ .pos(4, 9); }
	&.pos4x10 	{ .pos(4,10); }
}



/* Admin */

table.admin-datatable {
	border: 1px solid black;
	width: 100%;

	th { 	
		border: 1px solid black;
		color: @yellow;
		font-weight: bold;
		padding: 10px;
	}
	
	td { 
		border: 1px solid black;
		padding: 10px; 
	}
	
	&.admin-stats {
		margin: 20px 0 ;
		
		th, td {
			text-align: center;
		}
	}
	
	.actions {
		min-width: 120px;
		a {
			color: @yellow;
		}
	}
}

/* Media */



.sendage-media {
	float:left;
	margin-bottom: 10px;
	margin-right: 10px;
	
	.sendage-media-item {
		background-position: center center;
		border: 2px solid @yellow;
		border-radius: 5px;
		cursor: pointer;
		display: block;
		overflow: hidden;
		position: relative;
		height: 75px;
		width: 75px;
		
		&.small {
			height: 45px;
			width: 45px;
		}
	
		&.inprogress {	
			background: url("@{cdn}img/wait_spinner.gif") center center no-repeat;
		}

		.media-remove {
			height: 16px !important;
			padding-bottom: 4px;
			position: absolute;
			right: 0px;
			top: 1px;
			width: 16px !important;
		}
		
		.media-video {
			height: 16px !important;
			padding-bottom: 4px;
			position: absolute;
			right: 1px;
			bottom: 3px;
			width: 20px !important;	
		}
		
	}
}

.users_view {
	&.grid_8 >div.col.jTour_exposed {
		background-color: @bgcolor;
		border-radius: 5px;
		margin: -10px;
		padding: 10px;
	}
	#grade_graph {
		display:none;
		height:120px;
		margin: 2px auto;
		width:270px;	
	}
	
	#show_sendlists_btn {
		display: none;
		margin-top: 20px;
	}
	
	.user-details {
		margin-right: 0;
		width: 100%;
	}
	
	
	.profile-info {
		margin-right: 0;
		width: ~'calc(100% - 60px)';
	}

	&.loggedin {
		#show_sendlists_btn {
			margin-top: 10px;
		}
		
		.user-details {
			max-width: 100%;
			width: 440px;
		}
		
		.profile-info {
			width: 360px;
		}		
	}
	
	
	#mobile_sendlists_container {
		display: none;
		padding: 20px 0;
	}

	.profilepic-col {
		text-align: center;
	}
	
	a.follows {
		color: @gray;
		display: inline-block;
		margin: 5px 0;
	}
	
	.sidebar_panel.followees {
		.profilepic {
			//display: inline-block;
			//margin-: 2px;
			float: left;
		}
		
		.latest {
			color: @gray;
			float: left;
//			line-height: 1.3em;
			padding-left: 10px;
			width: ~"calc(100% - 34px)";
			
			a {
				color: @gray;
			}
		}
		>div {
			margin-bottom: 6px;
		}
	}
	
	.sidebar_panel.followers {
		.profilepic {
			display: inline-block;
			margin-: 2px;
		}
	}
	
	
	.grade-graph { 
		display: none; 
		.prompt;
		
		&.col { margin-left: 10px; }
		&.colRight { margin-right: 15px; }
	}
	sub.grade-graph {
		margin-left: 10px; 
		.highlight;
	}
	.year-graph { display: none; }

	.smaller_media_items;
	
	.logged_in_buttons {
		div {
			margin-bottom: 5px;
			
			a {
				text-align: left;
				width: 100%;
				
				&.major {
					margin-top: 10px;
				}
			}
		}
	}
	
	.karma-score,
	.user-links,
	.user-sponsors {
		box-sizing: border-box;
		color: @gray;
		float: left;
		margin-bottom: 10px;
		width: 38%;
		label {
			display: block;
		}
		.othersponsors {
			font-size: 11px;
			margin-top: 3px;
		}
	}
	.user-sponsors, .user-links {
		padding-right: 15px;
	}
	.karma-score {
		width: 24%;
		//text-align: right;
	}
}


#user_stats_btn {
	&.ui-button {
		//display: block;
	}
}	


.users_stats {


	#overall_chart {
		height: 50px;
		margin: 10px 0;
		width: 100px;
		
		.chart {
			height: 50px;
			width: 940px;
		}
	}

	.infographic {
		font-size: 32px;
		span {
		}
		
		label {
			color: @gray;
			display: inline-block;
		}
		
		.group {
			background-color: #eee;
			border-radius: 10px;
			display: block;
			margin: 0.3em 0;
			min-height: 8.7em;
			padding: 1em 0;
			position: relative;
			.inner_box_shadow(#666);
			
			.climb-items,
			.pie {
				display: none;
				position: absolute;
				height: 240px;
				right: 1em;
				top: 1em;
				width: 480px;
				
				.legend {
					table {
						font-size: 12px !important; 
						
						td {
							padding-bottom: 1px; 
						}
					}
				}
			}
		
			.moreinfo {
				left: 4em;
				position: absolute;
				text-align: center;
				top: 4em;
				width: 150px;
				
				span {
					display: block;
					line-height: 1em;
					font-size: 2em;
				}
				label {
					display:block;
					color: @gray;
					font-size: 0.4em;
					line-height: 0.2em;
				}
			}
			
			.climb-items {
				width: 550px;
				
				.climb-item {
					float: left;
					font-size: 12px;
					margin: 5px 10px;
					width: 255px;

					.trophy {
						float: left;
						margin-right: 6px;
					}				
					.info {
						color: @gray;
						float: left;
						width: 225px;


						label {
							color: @yellow;
							font-size: inherit;
							font-weight: bold;
						}
						
					}	
				}
			}	
		}
		
		.year { 
			color: @yellow;
			display: inline;
			float: left;
			font-size: 3em; 
			font-weight: bold;

			.transform(rotate(-90deg) translate(-0.6em, -0.5em));
			filter: ~"progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
			
		}
		.data {
			float: left;
			margin-left: -2.2em;
		}
		
		.days,
		.sends {
			float: left;
			margin-top: -0.6em;
		
			span {
				font-size: 5em; 
				letter-spacing: -0.05em;
				text-align: center;
			}
			
			label {
				font-size: 0.6em;
				.transform(rotate(-90deg) translate(2.5em, -1.8em));
			}
			
			&.smaller span {
				font-size: 4.4em;
			}
		}
		.days { margin-left: -1em; }
		
		.minor {
			float: left;
			margin-left: -1em;
		
			div {
				float: left;
			
				span {
					font-size: 2em; 
				}
				
				label {
					font-size: 0.35em;
					.transform(rotate(-90deg) translate(1.7em, -1em));
				}
				&.fas,&.locations {
					cursor: pointer;
					position: relative;
				}
				
				&.fas label { .transform(rotate(-90deg) translate(1.7em, 0em)); }
				
				&.clear { float: none; }
			}
		}
		
		
		.grade { 
			float: left;
			line-height: 0.6em;
			padding: 0.2em 1em 0 0;
			position: relative;
			text-align: center;
			cursor: pointer;
			
			span {
				font-size: 2.5em; 
			}
			
			label {
				font-size: 0.4em;
				/*letter-spacing: 0.1em;*/
			}
			.close_btn {
				top: -0.6em;
			}
			.chart {
				font-size: 16px;
				height: 50px;
				margin: -5px auto 0 auto;
				width: 100px;
			}
		}
		
		.close_btn {
			background: url("@{cdn}img/close-button-16x16.gif");
			display: none;
			height: 16px; 
			position: absolute;
			right: 0px;
			top: 0px;
			width: 16px;
		}
	}
	

}

.widget_index {
	table {

		th { 
			color: @yellow;
			font-weight: bold;
		}
		th,
		td {
			padding: 5px 10px 5px 0; 
			vertical-align: middle;
		}
		td {

			input[type=text] {
				width: 155px;
			}

			select[name=data-type],
			select[name=data-exclude] {
				width: 167px;
			}

			em {
				font-weight: bold;
				text-decoration: underline;
			}
		}
	}
	
	.buttons {
		margin-top: 30px;
	}
	
}
.code_dialog {
	
	textarea {
		font-family: courier new, monospace;
		height: 210px;
		width: 600px;
	}
}

span.media-title-source { font-size: 10px; margin-left: 15px; }
.media-error { color: red; }

table.listed-areas { margin: 10px 0 20px 0; }
.listed-areas td.label { width: 200px; }
.listed-areas td.num-climbs { text-align:right; }
.listed-areas th { color: @yellow; font-size: 16px; font-weight: bold; padding: 5px 0; }

#create_area_form {
	label { color: @yellow; display: block; margin: 10px 0 1px 0; }
	input[type=text] { width: 270px; }
	select { width: 281px; }
	input[type=submit] { margin-top: 20px; }
	.state_code.state, .state_code.province { display: none; }
}

ul.latest_sends {
	.recent_send { width: 245px; }
}

table.list-clones {
	td {
		
		padding: 2px 10px 2px 0;
		vertical-align: middle;
	}
}

.sponsored_content {
	display: none;
	margin-top: 15px;
	margin-bottom: 10px; 
	
	iframe {
		border-radius: 5px;
	}
	
	&:after {
		content: "Advertisement";
		color: #666;
		display: block;
		padding: 2px;
	}
	
}

/********** team ************/

.team-description p { margin: 8px 0; }
.team-thumb { 
	display: inline-block; 
	height: 24px; 
	width: 24px; 
	
	&.flashed {
		background-image: url("@{cdn}img/team/flashed/thumb-24.png");
	}
	
	&.evolv-canada {
		background-image: url("@{cdn}img/team/evolv/canada/thumb-24.png");
	}
}

.fb-like {
	margin: 5px 0 5px 0;
}

/******* Twitter overrides **********/
#twtr-widget-1 {
	.twtr-doc {
		.inner_box_shadow(#666);


		.twtr-hd {
			h4:before {
				content: '@';
			}
		}
		
		.twtr-timeline {
			-moz-border-radius: 0 !important;
			-webkit-border-radius: 0 !important;
			border-radius: 0 !important;
		}
	
	}
	

}

#home_welcome_banner {
	.slideshow { height: 130px; }
	
}

/* button hacks */
button.ui-sendlist-climb-remove {
	background: none;
	border: none;
	
	.ui-icon {
		background: url('@{cdn}img/trash.png') no-repeat;
	}
	
	&.ui-state-hover {
		.ui-icon {
			background: url('@{cdn}img/trash_active.png') no-repeat;			
		}
	}	
}
a.ui-sendlist-makecomment {
	background: none;
	border: none;
	
	.ui-icon {
		background: url('@{cdn}img/comment.png') no-repeat;
	}
	
	&.has-comments,
	&.ui-state-hover {
		.ui-icon {
			background: url('@{cdn}img/comment_active.png') no-repeat;			
		}
	}
}
button.ui-sendlist-addtosendlist {
	background: none;
	border: none;
	
	.ui-icon {
		background: url('@{cdn}img/add-to-sendlist.png') no-repeat;
	}

	&.ui-state-hover {
		.ui-icon {
			background: url('@{cdn}img/add-to-sendlist_active.png') no-repeat;			
		}
	}
}
button.ui-sendlist-logsend {
	background: none;
	border: none;
	
	.ui-icon {
		background: url('@{cdn}img/checkmark.png') no-repeat;
	}

	&.ui-state-hover {
		.ui-icon {
			background: url('@{cdn}img/checkmark_active.png') no-repeat;			
		}
	}
}

#terms_and_conditions,
#privacy_policy {
	h1 {
		padding-bottom: 1em;
	}
	strong {
		color: inherit;
	}
	p {
		line-height: 1.5em;
		margin-bottom: 1.5em;
	}
}

.user_notice {
	background-color: @orange;
	border-radius: 5px;
	color: white;
	margin: 10px 0;
	padding: 10px;
	text-align: center;
	.inner_box_shadow(darken(@orange, 20%));
	h4 {
		color: white;
		font-size: 1.2em;
		font-weight: bold;
		padding: 0 0 5px 0;
	}
}

#sendlist_page_container {
	overflow: hidden;
	padding: 20px 0;
	text-align: center;
	
	>div {
		margin-left: -26px;
		img {
			vertical-align: text-bottom;
			visibility: hidden;
			
		}
		label {
			cursor: pointer;
			color: @yellow;
			font-size: 16px;
			font-weight: bold;
			padding-left: 10px;
		}
		
		&.auto {
			margin-left: 0;
			label {
				display: none;
			}
		}	
	}
}


#sendlist_input_containers {
	margin-bottom:5px;	
	margin-top: 25px;
}

#page_comments {
	h3 {
		margin-top: 30px;
	}
}

#newsendage_dialog {
	.add-single-climbs {
		input {
			width: 92%;
			box-sizing: border-box;
		}
	
				
		label {
			color: @gray;
			display: inline-block;
			font-style: italic;
			margin-bottom: 5px;
		}
		p {
			margin-bottom: 5px;
		}
		
		.errors {
			height: 24px;
			position: relative;
		}
		
		.no-climb-found,
		.no-area-found,
		.climb-exists {
			color: @orange;
			display: none;
			font-weight: bold;
			padding-top: 10px;
			position: absolute;
		}
	}
}

#newclimbdetails {
	h3 {
		padding-top: 20px;
	}
}

#karma {
	padding: 0 20px;

	&.sidebar_panel {
		display: none;
		padding: 20px 10px;
		position: relative;
		&.loading {
			.content {
				background-image: url('@{cdn}img/wait_spinner.gif');
				background-position: center center;
				background-repeat: no-repeat;
			}
		}

		.content {
			height: 20px;

			//transition: height 0.5s, opacity 0.5s;

			.question {
				margin-top: 0px;
				min-height: 0;
				
				
				h3 {
					font-size: 14px;
					padding-top: 0;
				}
			}
			
			.answer {
				min-height: 0;
			}
			
		}

		.actions {
			display: none;
			margin: 20px 0 0;
			min-height: 0;
			text-align: left;
			
			button {
				margin-bottom: 10px;
			}
		}
		
		.beta {
			color: @gray;
			font-size: 11px;
			font-style: italic;
			padding-top: 20px;
		}
	}

	.question {
		margin-top: 30px;
		min-height: 60px;
	}
	
	.answer {
		min-height: 200px;
		
		form {
			ul {
				li {
					display: block;
					margin: 0;
					padding: 3px 0;
					
					.fa {
						//color: @orange;
						cursor: pointer;
						font-size: 14px;
						margin-left: 5px;
					}
				}
			}
			li.children {
				display: none;
				padding: 0 0 3px 25px;
			}
		}
	}
	
	.actions {
		margin: 20px 0 50px;
		min-height: 50px;
		text-align: right;
		.skip {
			color: @gray;
			cursor: pointer;
			padding-right: 20px;
			
			&:hover {
				color: darken(@gray, 30%);
			}
		}
		
	}
}

#karma-leaderboard {
	
	p {
		margin: 8px 0;
	}
	
	table {
		th,td {
			padding: 5px 0;
		}
		th {
			color: @yellow;
			font-weight: bold;
			width: 60px;
		}
		td {
			color: @gray;
		}
	}
	
	.sidebar_panel {
		&.img {
			background-position: center center;
			background-repeat: no-repeat;
			
		}
	}
	
	ol {
		padding-left: 20px;
		margin: 30px auto;
		max-width: 100%;
		width: 500px;
		
		li {
			border-bottom: 1px solid @gray;
			display: block;
			list-style: none;
			padding: 5px 0;	
			position: relative;
			width: 100%;
			
			
			&:before {
				content: "";
				display: list-item;
				font-size: 16px;
				font-weight: bold;
				line-height: 24px;
				list-style: decimal;
				position: absolute;
				
			}			
			
			>div {
				.clearfix;
				display: inline-block;
				width: 100%;
				>* {
					
				}
				
				>div {
					box-sizing: border-box;
					float: left;
					height: 24px;
				}
				
				a {
					text-decoration: none;
				}
				
				.pic {
					width: 30px;
				}
				.name {
					line-height: 24px;
					font-size: 16px;
					font-weight: bold;
					width: 70%; 
				}
				.score {
					font-size: 16px;
					font-weight: bold;
					line-height: 24px;
					text-align: right;
					width:  ~'calc(30% - 30px)';
				}

			}
			
		}
	}
}

.products_view {
	
	.product {
		width: 100%;
		
		.image,.info { box-sizing: border-box; }

		.image {
			float: left;
			padding-right: 20px;
			width: 33.3333%;
		}
		
		.info {
			float: left;
			padding-top: 10px;
			width: 66.66667%;
			
			.price {
				color: @orange;
				font-weight: bold;
				font-size: 3em;
			}
			
			.shipping {
				color: @gray;
				margin-top: 5px;
			}
			
			.description {
				color: @gray;
				font-size: 1.25em;
//				line-height: 1.1em;
				padding: 10px 0;
			}
			.buynow {
				margin-top: 10px;
			}
			
			ul.notes {
				color: @gray;
				padding: 20px 0 20px 10px;
				li {
					list-style-type: disc;
				}
			}
		}
	}
}

.comment_container {
	background-color: white;
	height: 100%;
	width: 100%;
	.content {
		background-color: black;
		float: left;
		height: 100%;
		text-align: center;
		width: 66.6666%;
		
		.image {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
			height: 100%;
			width: 100%;
		}
	}
	
	.comments {
		height: 100%;
		float: right;
		overflow-y: scroll;
		padding: 20px;
		width: 33.3333%;
		
		box-sizing: border-box;
		
		.meta {
			margin-bottom: 40px;
			
			h3 {
				color: black;
				float: left;
				padding: 0;
			}
			>a {
				display: block;
				float: right;
				line-height: 20px;
			}
		}
	}
}

#cboxOverlay {
	background-color: fadeout(#000, 20%);
}

.media_view {
	>.content {
		text-align: center;
		img {
			max-width: 100%;
		}
	}
}


/******* 960.gs overrides **********/

#centerpane2 .grid_8 {
//	border-right: 1px solid #CCC;
//	margin-right: 0px;
//	padding-right: 9px;
}

/********* tour *******/

#user_panel.jTour_exposed {
	position: fixed !important;
}

/************ print ***************/

.list-print-preview {
	color: @yellow;
	font-weight: bold;
	height: 25px;
	line-height: 30px;
}

.print {
		ul.ui-sendlist, ul.ui-sendlist li { padding: 0; }

	h1 { color: black; }
	.checkbox {
		border: 1px solid #444;
		border-radius: 5px;
		font-size: 18px;
		height: 18px;
		line-height:18px;
		margin: 2px 0 2px 0;
		text-align: center;
		width: 18px;
	}
	
	&.sendlist {
		.description {
			color: black; 
			font-size: 12px;
			text-align: justify;
		}
	}	
	.ui-sendlist-climb-label {
		display: block;
		font-size: 11px; 
	}
	
	sup { font-size: 10px; }
	
	.page-break  { display:block; page-break-before:always; }
}

.toggle-to-sendlist {
	color: #9a9a9a;
	cursor: pointer;
	display: none;
	line-height: 18px;
	
	body.editing-sendlist & {
		display: inline;
	}

	body.editing-sendlist.viewing-editing-sendlist & {
		display: none;

	}

	&.added {
		color: @orange;
	}
}


#search2 {
	min-height: 677px;

	.tab-container {
		box-sizing: border-box;
		padding: 0 10px;
		width: 100%;
		
	}

	.tabs {
		.clearfix;
		border-bottom: 2px solid @orange;
		margin-bottom: 20px;
		width: 100%;

		.tab {
			border: 1px solid @yellow;
			border-bottom: none;
			border-top-right-radius: 6px;
			border-top-left-radius: 6px;
			color: @gray;
			cursor: pointer;
			float: left;
			padding: 3px 8px;

			label {
				cursor: pointer;
			}
			
			&:hover {
				background-color: @yellow;
				border-color: @yellow;
				color: white;
			}
			
			&.active {
				background-color: @orange;
				border-color: @orange;
				color: white;
				
			}
		}	
	}
	
	.tab-data {
		display: none;
		&.active {
			display: block;
		}
	}
	

	&.with-actions {
		.dataset {
			>div >div {
				&.climb {
					width: ~'calc(40% - 35px)';
				}
				&.actions {
					display: block;
					width: 35px;
				}
			}
		}
		
		.actions {
			.clearfix;
			.toggle-to-sendlist {
				float: right;
			}
		}
	}

	#filters {
		* {
			box-sizing: border-box;
		}

		dl {
			.clearfix;
			dt {
				clear: left;
				float: left;
				line-height: 2em;
				padding-right: 5px;
				text-align: right;
				width: 27%;
			}

			dd {
				float: left;
				padding-left: 5px;
				width: 73%;

				input + label {
					color: @gray;
					font-size: 11px;
					padding-left: 5px;
				}
			}

			dt,dd {
				margin-bottom: 10px;
			}
		}

		input[type=text] {
			width: 100%;
		}

		.ui-slider {
			margin: 7px auto 0;
			width: 94%;
		}

		.min_sends {
			.clearfix;
			.slider_container, .count {
				float: left;
			}

			.slider_container {
				padding: 6px 8px 0;
				width: 90%;
			}

			.count {
				line-height: 2em;
				text-align: right;
				width: 10%;
			}
		}

		.grade_range {
			.clearfix;
			.slider_container, .gmin, .gmax {
				float: left;
			}

			.slider_container {
				padding: 6px 12px 0;
				width: 68%;
			}

			.gmin, .gmax {
				line-height: 2em;
				width: 16%;
			}

			.gmin {
				text-align: right;
			}

		}

		#min_rating {
			//padding: 3px 0 0;
			a {
				text-decoration: none;
				color: #aaa;
				font-size: 16px;
				line-height: 1.6em;
				padding: 0 1px;

				&.selected {
					color: @orange;
				}
				&.hover {
					color: @yellow;
				}

			}
		}

		#area_selections {
			//padding: 0 5px;

			li {
				background-color: #cecece;
				border-radius: 4px;
				cursor: pointer;
				font-size: 11px;
				padding: 5px 55px 5px 5px;
				position: relative;
				&:after {
					color: fade(darken(red, 10%), 50%);
					content: 'remove';
					display: block;
					padding: 5px;
					position: absolute;
					right: 0;
					top: 0;
					
				}
				&:hover {
					background-color: #bbb;
					&:after {
						color: darken(red, 10%);
					}
				}
			}
		}

		#reset_filters {
			display: block;
			margin-top: 20px;
			text-align: center;
		}
	}

	/* TODO
	FIX THIS CSS MESS. Trying to
	**/

	.dataset {
		width: 100%;

		>div {
			.clearfix;
			>div {
				float: left;
				padding: 5px 0;
				box-sizing: border-box;

				&.climb {
					padding-right: 10px;
					width: 40%;
				}

				&.grade, &.sends, &.rating {
					text-align: center;
					width: 20%;
				}

				&.actions {
					display: none;
				}
			}
		}

		&.header {
			border-bottom: 1px solid #bbb;
			margin-bottom: 10px;

			.climb a {
				padding-left: 0;
			}

			a {
				color: @orange;
				font-weight: bold;
				padding: 0 13px;
				position: relative;
				text-decoration: none;

				&.asc:after {
					.arrow_up(5px, 5px, @orange);
					right: 0;
					top: 5px;
				}
				&.desc:after {
					.arrow_down(5px, 5px, @orange);
					right: 0;
					top: 5px;
				}
			}
		}
	}

	.dataset {
		>div {
			>div {
				&.climb {
					a {
						font-weight: bold;
					}

					div, div>a {
						color: @yellow;
						font-style: italic;
						font-weight: normal;
					}
				}

				&.grade, &.sends {
					//text-align: right;
				}
			}
		}
	}

	.num_results.full {
		font-weight: bold;
		line-height: 12px;
		padding: 0 0px 10px 0;
		text-align: right;

		i.fa {
			font-size: 14px;
			line-height: 10px;
			margin-right: 10px;
		}
	}

	#result_footer {
		display: none;
	}
	#pager,#prevnext {
		margin-top: 20px;
		a {
			display: inline-block;
			font-weight: bold;
			margin: 0 5px;
			text-decoration: none;

			&.selected {
				color: @yellow;
				cursor: default;
			}
			&.ignore {
				cursor: default;
			}
		}
	}

	#pager {
		float: left;
	}

	#prevnext {
		float: right;
	}
	
	
	form.search {
		margin-bottom: 30px;
	}
	
	.list_results {
		.list_result {
			margin-bottom: 20px;
			
			a {
				color: @gray;
				font-weight: bold;
				text-decoration: none;
			}
		}
	}
	
	#user_results {
		
		h3 {
			color: @orange;
			font-size: 14px;
			margin-bottom: 15px;
		}
		
		.user {
			//line-height: 24px;
			margin-bottom: 20px;
			
			.profilepic {
				float: left;
				margin-right: 5px;
			}
			a {
				color: @gray;
				display: block;
				//float: left;
				font-weight: bold;
				text-decoration: none;
			}
			//
			//margin: 0 20px;
			
		}
	}
	
	.old-search {
		margin-top: 40px;
		text-align: center;
		
		a {
			color: @gray;
		}
	}
	
	&.area-results {
		.num_results, .filter-toggle {
			float: right;
		}
		
		.filter-toggle {
			font-weight: bold;
			
			a {
				text-decoration: none;
			}
		}
		
		#filters {
			display: none;
			margin-bottom: 20px;
			
			#reset_filters {
				margin-top: 0;
			}
		}
		.dataset {
			>div >div.climb > div { display: none; }
		}
	}
	
}

// FONT AWESOME OVERRIDES
.fa-spin {
	animation: spin 1s infinite linear;
}

.trophy-bg {
	background-color: white;
	//background-image: url('../../img/logotextbg.png');
	background-size: cover;
	display: inline-block;
	font-size: 200px;
	padding: 20px 143px; 	
}

@color-redpoint: #A2592C;
@color-flash: #9F9F9F;
@color-onsight: #E89C04;

.fa-trophy {
	color: #ededed;

	&.project {
		color: fade(#A2592C, 60%);
	}

	&.redpoint {
		color: #A2592C;
	}

	&.flash {
		color: #B3B3B3;
	}

	&.onsight {
		color: #E89C04;
	}
}
.fa-icon {
	&.repeat, &.project {
		color: #A2592C;	
	}
}
.fa-trophy.add-send {
	font-size: 1.5em;
}

.trophy.fa-stack {
	line-height: 1.35em;

	.fa-trophy {
		+.fa-icon {
			font-size: 0.85em;
		}
	}

	.fa-icon,
	.fa-trophy {
		text-shadow: 0px 1px 2px fade(black, 20%);
	}

	.fa-star {
		display: none;
	}
	
	&.first-ascent {
		.fa-star {
			display: block;
		}
		.fa-check-circle {
			display: none;
		}
		
		&.redpoint, &.flash, &.onsight {
			.fa-icon {
				color: #E1D70B;
			}
		}
		
		&.project {
			.fa-star {
				display: none;
			}
		}
	}

	&.project {
		.fa-trophy {
			color: fade(#A2592C, 50%);
		}
		.fa-icon {
			color: fade(#7A2A21, 50%);
		}
	}

	&.redpoint {
		.fa-trophy {
			color: @color-redpoint;	
		}
		.fa-icon {
			color: #7A2A21;
			text-shadow: 0px 1px 2px fade(#333, 10%);
		}
		label {
			background-color: darken(@color-redpoint, 10%);
			color: lighten(@color-redpoint, 10%);
		}
	}

	&.flash {
		.fa-trophy {
			color: @color-flash;
		}
		.fa-icon {
			color: #726969;
		}
		label {
			background-color: darken(@color-flash, 10%);
			color: darken(@color-flash, 40%);
		}
	}

	&.onsight {
		.fa-trophy {
			color: @color-onsight;
		}
		.fa-icon {
			color: #9D5A03;
		}
		label {
			background-color: darken(@color-onsight, 10%);
			color: lighten(@color-onsight, 20%);
		}
	}


	label {
		border-radius: 3px;
		bottom: 1.7em;
		box-sizing: border-box;		
		box-shadow: 0px 1px 2px fadeout(black, 30%);
		display: block;
		font-size: 0.125em;
		left: 50%;
		line-height: normal;
		margin: 0px auto 0px -17%;
		padding: 0.2em 0 0.1em;
		position: absolute;
		text-align: center;
		text-transform: uppercase;
		width: 34%;
	}
}

.fa-stack.add-to-list {
	cursor: pointer;
	font-size: 1.2em;
	height: 1em;
	line-height: 1em;
	width: 1em;

	.fa-list-ul {
		color: @yellow;
		//font-size: 1.5em;
	}

	.fa-plus {
		color: @orange;
		font-size: 0.8em;
		left: auto;
		right: -3px;
		text-align: right;
		top: -3px;
	}
}

// NOUI SLIDER DEFAULTS
.noUi-target *{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;-webkit-touch-callout:none;-ms-touch-action:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;cursor:default}.noUi-base{width:100%;height:40px;position:relative;max-width:100%;max-height:100%;border:1px solid #BFBFBF;z-index:1}.noUi-handle{background:#EEE;height:44px;width:44px;border:1px solid #BFBFBF;margin:-3px 0 0 -23px}.noUi-active{background:#E9E9E9}.noUi-active:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;content:"";display:block;height:100%;border:1px solid #DDD}.noUi-connect{background:Teal}.noUi-background{background:#DDD}.noUi-origin{position:absolute;right:0;top:0;bottom:0;z-index:0;border-radius:inherit}.noUi-origin-upper{background:inherit!important}.noUi-z-index{z-index:10}.noUi-vertical{width:40px;height:100%}.noUi-vertical .noUi-origin{bottom:0;left:0}.noUi-vertical .noUi-handle{margin:-23px 0 0 -3px}.noUi-target[disabled] .noUi-base{background:#999}.noUi-target[disabled] .noUi-connect{background:#BBB}.noUi-state-tap .noUi-origin{-webkit-transition:left .3s,top .3s;transition:left .3s,top .3s}

.noUi-base {
	background-color: #cecece;
	height: 12px;
}
.noUi-handle {
	background-color: lighten(@yellow, 15%);
	border: 1px solid darken(@yellow, 5%);
	border-radius: 3px;
	cursor: pointer;
	height: 16px;
	margin-left: -8px;
	width: 16px;
}


.fa-star-rating {
	color: #aaa;
	font-size: 16px;
	.on {
		color: @yellow;
	}
}

.add-8a-climbs {
	ul {
		
		color: @gray;
		padding-left: 15px;
		
		li {
			list-style-type: disc;	
		}
	}
}

#sendlist-editing-panel {
	background-color: @bgcolor;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	// border: 2px solid @orange;
	// border-top: none;
	box-shadow: 0px 1px 5px #222;
	box-sizing: border-box;
	color: @orange;
	font-size: 18px;
	height: 32px;
	left: 50%;
	line-height: 32px;
	margin-left: -980px / 2;
	padding: 0px 10px;
	position: fixed;
	top: 0;
	width: 980px;
	z-index: 999999;


	.new-list-clicked & {
		left: 0%;
		margin-left: -980px;
		transition: all 0.4s cubic-bezier(1.000, 0.095, 0.545, 1.015);
	}

	&.just-added {
		left: 50%;
		margin-left: -980px / 2;
	}
	
	i, a, span {
		display: inline-block;
		padding: 0 2px;
	}

	a.link-to {
		font-size: 14px;
		// max-width: 400px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		@media screen and (max-width: 440px) {
			max-width: 225px;
		}
		
		@media screen and (max-width: 350px) {
			max-width: 175px;
		}
	}

	.status {
		float: left;
		margin-right: 10px;
	}

	.actions {
		float: right;
		i, a {
			cursor: pointer;
			padding: 0 5px;
			text-decoration: none;

			&.unavailable {
				display: none;
			}
		}
	}

	@media screen and (max-width: 980px) {
		border-left: none;
		border-right: none;
		border-radius: 0;
		left: 0;
		margin-left: 0;
		width: 100%;

		.new-list-clicked & {
			left: -100%;
			margin-left: 0;
			transition: all 0.4s cubic-bezier(1.000, 0.095, 0.545, 1.015);
		}

		&.just-added {
			left: 0;
			margin-left: 0;
		}

	}



}


/* END DESKTOP CSS */
/************************************************************************************************/

.mobile() {
	html {
		-webkit-text-size-adjust: none;
	}
}

.small_header() {
	#header {
		* { 
			box-sizing: border-box; 
			word-wrap: break-word;
		}
		padding: 5px 0 10px 0;
		.logopane {
			height: auto;
			width: auto;
		
			.boulders,.banner {
				display: none;
			}
			
			.navigation {
				background: none !important;
				height: auto;
				left: auto;
				margin-left: 0;
				position: relative;
				width: auto;
				a {
					color: @yellow;
					display: inline-block;
					height: auto !important;
					font-size: 1.4em;
					left: auto !important;
					line-height: 1.6em;
					padding: 0 8px;
					position: relative;
					text-indent: 0;
					text-decoration: none;
					top: auto !important;
					width: auto !important;
					
					&:hover {
						background: none !important;
					}
					
					&.home {
						background-image: url('@{cdn}img/SendageTextLogo.png') !important;
						background-position: 0 0 !important;
						text-indent: -9999px;
						display: block;
						margin-left: 10px;
						margin-bottom: 10px;
						height: 30px !important;
						width: 165px !important;
					}
					
					&.community {
					//	float: right;
						
					}

					&.create {
						.badge {
							background-color: @orange;
							border-radius: 8px;
							color: #fff;
							display: inline-block;
							font-family: monospace;
							font-size: 12px;
							line-height: 18px;
							margin-left: 6px;		
							padding: 0 5px;					
							position: relative;
							text-align: center;
							
						}
					}
				}
			}
		}
		
		.social {
			bottom: auto;
			padding-right: 2px;
			right: 5px;
			top: 18px;
			
			a {
				font-size: 1.75em;
				margin-left: 8px;
			}
		}
		
	}
	#user_panel {
		display: none;
	}
	#stickyfooter-wrapper {
		background: none;
		background-color: black;
	}
}

.mobile_iphone() {
	min-width: inherit;

	* { 
		box-sizing: border-box; 
		word-wrap: break-word;
	}

	.dev-banner {
		display: none;
	}

	select {
		//padding: 5px 0;
	}
	
	#stickyfooter-wrapper {
		background: none;
		background-color: black;
		width: 100%;
	}
	
	.container_12 {
		.grid_1, .grid_2, .grid_3, .grid_4, .grid_5, .grid_6, .grid_7, .grid_8, .grid_9, .grid_10, .grid_11, .grid_12 {
			width: auto;
		}
	}
	

	#centerpane2 {
		border-left: none;
		border-right: none;
		border-radius: 0;
		width: auto;
		
		>.grid_4.pages_home {
			display: none;
		}
	}	
	
	
	#centerpane,
	#footer {
		width: auto;
	}
	
	.container_12 {
		width: auto;
		
		.grid_8 {
			clear: both;
			float: none;
			display: block;
			width: auto;
		}
	}
	
	ul.home-feed li {
		>.col {
			margin-right: 0;
			width: 15%;
		}
		>.colLast {
			width: 85%;
			
			.feed-send-trophy {
				//margin-right: 0;
				//padding-right: 10px;
				//width: 20%;
			}
			.feed-send-title {
				float: none;
				margin-right: 0;
				
				.feed-send-description {
					margin-top: 25px;
				}
			}
		}
	}
	
	.feed-list-description {
		width: auto;
	}
	.feed-send-description {
		font-size: 13px;
		width: auto;
	}
	#stickyfooter-wrapper {
		margin: 0 auto -90px;
	}
	
	#stickyfooter-push, #footer {
		height: 90px;
	}
	
	#footer {
		.links {
			line-height: 1.5em;
			text-align: left;
		}
		.footer-link {
			margin: 0 10px 0 0;
			padding: 0;
		
			&.bug,
			&.feature,
			&.review {
				display: none;
			}
			&.copyright {
				
			}
			
			&:after {
				content: '';
			}
			
			a {
				margin: 0;
				padding: 0;
			}
		}
	}
	
	form.search input[name="q"] {
//		width: 75%;
	}
	.search_faq {
		display: none;
	}
	
	sub {
		font-size: inherit;
	}
	
	.container_12 .grid_6 {
		display: block;
		float: none;
		width: auto;
	}
	.sendlist_create #sendlist_name, 
	.sendlist_create #sendlist_description {
		width: 100%;	
	}

	#sendlist_info {
		width: 100%;
	}
	#sendlist_edit_links {
		width: 100%;
		text-align: left;
	}

	.sendlists_view {
		
		.progress,
		.like_container {
			display: none;
		}
	}
	
	
	.users_view {
		.user-details {
			float: none;
			margin-right: 0;
		}
		.user-sponsors, .user-links {
			//width: 50%;
		}
		.user-sponsors {
			margin-right: 0;
			padding-right: 10px;
		}
		.profilepic-col {
			display: none;
		}
		.profile-info {
			float: none;
			margin-right: 0;
			width: 100%;
		}
		.profile-bio {
			padding-left: 0;
			width: auto;
			&.loggedin {
				width: auto;
			}
		}
		.logged_in_buttons {
			float: left;
			margin: 0;
		}
		
		#sendage_media_container {
			display: none;
		}
		
		.logged_in_buttons {
			padding-top: 10px;
			div {
				display: inline-block;
			}
			#show_media_panel_btn, #widget_builder_btn {
				display: none;
			}
		}
		
		#show_sendlists_btn {
			display: inline-block;
		}
		
		
		#sendlist_input_containers {
			.colRight {
//				float: left;
//				margin: 0 0 10px 0;

				#sendlist_sort_container {
//					margin-right: 10px;
				}
				#sendlist_search_container  {
					display: none;
				}
			}
		}
		
		
		&.sidebar {
			//display: block;
			display: none;
			float: none;
			margin: 10px;
			width: auto;
		
			.sidebar_panel {
				width: auto;
			}
		}
		
	}
	
	.climbs_view {
		
		/* sidebar */
		&.grid_4 {
			display: none;
		}

		&.grid_8 {
			.climb-info {
				
				.col {
					width: auto !important;
					margin-bottom: 5px;
					margin-right: 0;
				}
				h1 {
					padding-bottom: 0;
					padding-right: 10px;
				}
				.climb-rating {
					clear: both;
					float: left;
					margin-bottom: 10px;
				}
				.colRight {
					float: left;
					margin-left: 0;
					
					.fb-like {
						display: none;
					}
				}
			}
			ul.climb-beta {
				li {
					.avatar {
						display: none;						
					}	
					.comments {
						margin-right: 0;
						width: auto;
						
					}		
					
				}				
			}
			ul.recent-sendage {
				li {
					>.col {
						margin-right: 0;
						padding-right: 10px;
					}
					>.trophy {
						width: 10%;
					}
					>.avatar {
						display: none;
					}
					>.title {
						width: 30%;
					}
					>.comments {
						padding-right: 0;
						width: 60%;
					}
				}
				
			}
		}
		
	}
	
	.user_climbs_view {
		&.grid_8 {
			.title {
				width: 44%;
			}
		}
		.trophy-count {
			width: 74px;
		}
		&.grid_4 {
			display: none;
		}
	}
	
	.areas_index {
		.grid_12,
		.grid_3 {
			display: block;
			float: none;
		}
	}
	
	.areas_view {
		.title {
			width: auto;
		}
		.fb-like {
			display: none;
		}
		&.sidebar {
			display: block;
			//display: none;
			float: none;
			margin: 20px 10px;
			width: auto;
		
			.sidebar_panel {
				width: auto !important;
			}
		}
		.search-filters {
			>.col {
				margin-right: 0;
				width: 40%;
			}
			>.colRight {
				margin-left: 0;
			}
			#result_type {
				width: 100%;
			}
		}
	}
	
	ul.ui-sendlist {
		padding: 0;
		
		li {
			div.sendlist-action-buttons {
				.ui-sendlist-makecomment {
					display: none;
				}
			}
			
			div.rating {
				float: left;
				.fa-star-rating {
					font-size: 10px;
				}
			}
			
			.col, .colRight {
				margin: 0;
			}
			.col {
				padding-right: 8px;
			}
			>.trophy.col {
				width: 40px;
			}
			>.info.col {
				width: ~'calc(100% - 115px)';
			}
			>.grade.col {
				width: 60px;
			}
			>.sendlist-action-buttons.colRight {
				margin-top: 0;
				text-align: right;
				width: 15px;
				
				>div,>button {
					clear: right;
					float: right;
//					display: inline-block;
				}
			}
			>.comments.col {
				clear: both;
				padding: 5px 0 0 40px;
				width: 100%;
			}
			
			sub {
				font-size: 11px;
			}
		}
	}
	
	.ui-dialog {
		left: 0 !important;
		width: 100% !important;
	}
	#newsendage_dialog {
		.add-single-climbs {
			.col,.colRight {
				display: block;
				float: none;
				margin: 0;
				width: auto;
				
				input {
					width: 80%;
				}
			}
		}
	}
	
	#sendage-dialog {
		#sendage-dialog-datepicker {
			width: 70%;
		}
		
		#sendlist_media_uploader {
			display: none;
		}
		
		.rating-container {
			margin-bottom: 15px;
		}
		.grade-picker {
			clear: both;
		}
		
		.nonproject.last.colRight {
			float: left;
			padding-bottom: 10px;
		}
		
		.sendage-dialog-beta, .sendage-dialog-comment {
			width: 100%;
		}
		.trophy-selector {
			.trophy-count {
				width: 60px;
			}
			
			.minor {
				display: block;
				margin-top: 15px;
			}
		}
		
		.day {
			>.col {
				float: none;
			}
		}
	}
	#searchResults {
		.col {
			padding-right: 10px;
			margin: 0;
		}
		.climb { width: 41%; } 
		.grade { 
			padding-right: 2px;
			width: 10%; 
		} 
		.sends { 
			padding-right: 3px;
			width: 7%; 
		}
		.sends-label { 
			width: 11%;
		}
		.rating { 
			margin-left: 0;
			width: 31%; 
			>div {
				float: right;
				width: 85px;
			}
		}
	}
	
	#page_comments {
		//display: none;
	}
	
	.products_view {
		
		.product {
			.image,.info {
				width: 100%;
			}
		}
	}
	
	.comment_container {
		.content {
			//width: 66.6666%;
			width: 100%;
		}
		
		.comments {
			display: none;
		}
	}

	#search2 {
		.grid_4,
		.grid_8 {
			//box-sizing: border-box;
			//float: none;
			//padding: 0 10px;
		}

		#filters #reset_filters {
			margin: 10px 0;
		}
		.num_results.full {
			//margin-top: 30px;
		}
		
		.old-search {
			
			margin: 10px 0 40px;
		}
	}	
	
	.areas_view {
		.area_children {
			.group {
				padding: 0 10px;
				width: 100%;
			}
		}
	}	
		
	
}

.mobile_iphone_portrait() {
	ul.home-feed li {
		>.col {
			width: 20%;
		}
		>.colLast {
			width: 80%;
		}
	}
	
	.climbs_view {
		&.grid_8 {
			ul.recent-sendage {
				li {
					position: relative;
					>.trophy {
						width: 50px;
						.fa-stack {
							font-size: 14px;
						}
					}
					>.title {
						width: 100px;
					}
					>.comments {
						width: ~'calc(100% - 150px)';						
					}
					.prompt {
						font-size: 10px;
					}
				}
				
			}
		}
	}

	#search2 {
		.dataset {
			>div {
				>div {
					font-size: 10px;

					&.climb {
						width: ~'calc(100% - 180px)';
					}
					&.grade {
						width: 60px;
					}
					&.sends {
						width: 60px;
					}
					&.rating {
						width: 	60px;
					}
					&.actions {
						width: 28px;
					}
				}
			}
		}

		&.with-actions {
			.dataset {
				>div >div {
					&.climb {
						width: ~'calc(100% - 208px)';
					}
				}
			}
		}

		.dataset {
			>div {
				>div {
					&.rating {
						font-size: 10px;
					}
				}
			}
		}
	}
	
}

.small_header();

.mobile_retina() {
	
}

@media screen and (max-width: 799px) {
	.mobile;
}

@media screen and (max-width: 699px) {
	body.mobile-device {
		.mobile_iphone;
	}
}

@media screen and (max-width: 479px) {
	body.mobile-device {
		.mobile_iphone_portrait;
	}
}


@media
only screen and (-webkit-min-device-pixel-ratio : 2),
only screen and (-moz-min-device-pixel-ratio : 2),
only screen and (-o-min-device-pixel-ratio : 2),
only screen and (min-device-pixel-ratio : 2) {
	.mobile_retina;  
}


