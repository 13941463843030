/* In order to deal with deployment configuration this following line MUST be on its own line. */
/* =Colors
----------------------------------------------- */
/* =Mixins
----------------------------------------------- */
.small-button button {
  height: 16px;
  width: 16px;
  box-shadow: 0px 0px 0px #fff;
}
.small-button a {
  height: 14px;
  width: 14px;
  box-shadow: 0px 0px 0px #fff;
}
.small-button span.ui-button-text {
  padding: 0;
}
* {
  margin: 0;
}
html {
  height: 100%;
  margin: 0 0;
  padding: 0 0;
}
html,
body {
  background-color: black;
}
html.print,
body.print {
  background-color: transparent;
}
body {
  box-sizing: border-box;
  color: #141313;
  font-family: Segoe UI, Arial, sans-serif;
  /*Helvetica, Arial, sans-serif*/
  *font-family: Arial, sans-serif;
  font-size: 12px;
  height: 100%;
  margin: 0;
  padding: 35px 0 0 0;
}
.video_container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.video_container iframe,
.video_container object,
.video_container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#gbanner {
  text-align: center;
}
#gbanner .adsbygoogle {
  margin-bottom: 10px;
  margin-top: 5px;
}
.gadmax {
  width: 320px;
  height: 50px;
}
@media (min-width: 468px) {
  .gadmax {
    min-width: 400px;
    max-width: 970px;
    width: 100%;
    height: 90px;
  }
}
#header {
  padding-top: 40px;
  position: relative;
}
#header.dev {
  padding-top: 90px;
}
#header .logopane {
  height: 200px;
  margin: 0 auto;
  padding-top: 5px;
  position: relative;
  width: 396px;
}
#header .logopane .boulders {
  background-image: url("/img/sendage-boulders-130x130.png");
  height: 130px;
  margin-left: 138px;
  margin-top: 50px;
  position: absolute;
  width: 130px;
}
#header .logopane .banner {
  background-image: url("/img/sendage-banner-396x128.png");
  height: 128px;
  margin: 105px auto 0;
  position: absolute;
  width: 396px;
}
#header .logopane .banner a.home-banner {
  display: block;
  height: 40px;
  left: 102px;
  position: absolute;
  top: 82px;
  width: 190px;
}
#header .logopane .navigation {
  height: 160px;
  left: 50%;
  margin-left: -120px;
  position: absolute;
  width: 240px;
}
#header .logopane .navigation a {
  background-image: none;
  display: block;
  position: absolute;
  text-indent: -99999px;
}
#header .logopane .navigation.loggedout {
  background-image: url("/img/sendage-navigation-loggedout.png");
}
#header .logopane .navigation.loggedout a.home {
  background-position: 0px -253px;
  height: 60px;
  left: 0px;
  top: 93px;
  width: 20px;
}
#header .logopane .navigation.loggedout a.search {
  background-position: -10px -180px;
  height: 61px;
  left: 10px;
  top: 20px;
  width: 56px;
}
#header .logopane .navigation.loggedout a.create {
  background-position: -77px -160px;
  height: 25px;
  left: 77px;
  top: 0px;
  width: 92px;
}
#header .logopane .navigation.loggedout a.about {
  background-position: -178px -184px;
  height: 52px;
  left: 178px;
  top: 24px;
  width: 50px;
}
#header .logopane .navigation.loggedout a.user {
  background-position: -222px -250px;
  height: 62px;
  left: 222px;
  top: 90px;
  width: 18px;
}
#header .logopane .navigation.loggedout a:hover {
  background-image: url("/img/sendage-navigation-loggedout.png");
}
#header .logopane .navigation.loggedin {
  background-image: url("/img/sendage-navigation-loggedin.png");
}
#header .logopane .navigation.loggedin a.home {
  background-position: 0px -258px;
  height: 56px;
  left: 0px;
  top: 98px;
  width: 20px;
}
#header .logopane .navigation.loggedin a.search {
  background-position: -9px -187px;
  height: 60px;
  left: 9px;
  top: 27px;
  width: 48px;
}
#header .logopane .navigation.loggedin a.create {
  background-position: -66px -160px;
  height: 30px;
  left: 66px;
  top: 0px;
  width: 84px;
}
#header .logopane .navigation.loggedin a.about {
  background-position: -161px -174px;
  height: 43px;
  left: 161px;
  top: 14px;
  width: 53px;
}
#header .logopane .navigation.loggedin a.user {
  background-position: -213px -226px;
  height: 88px;
  left: 213px;
  top: 66px;
  width: 28px;
}
#header .logopane .navigation.loggedin a:hover {
  background-image: url("/img/sendage-navigation-loggedin.png");
}
#header .navpane {
  display: none;
  height: 40px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 940px;
}
#header .navpane a {
  text-decoration: none;
}
#header .navpane .col {
  margin-right: 133px;
}
#header .navpane .colRight {
  margin-left: 0px;
}
#header .social {
  bottom: 5px;
  position: absolute;
  right: 0;
}
#header .social a {
  color: #DF9300;
  display: inline-block;
  font-size: 2.25em;
  margin-left: 3px;
  outline: none;
}
#header .social a:active {
  outline: none;
}
/* below the header. Holds the main content - centered */
#centerpane {
  background-color: #EEE;
  border: 2px solid #DF9300;
  border-radius: 5px;
  margin: 0 auto;
  min-height: 250px;
  padding: 40px 10px 10px 10px;
  width: 940px;
}
#centerpane2 {
  background-color: #EEE;
  border: 2px solid #DF9300;
  border-radius: 5px;
  min-height: 250px;
  padding: 40px 0 10px 0;
}
#footer {
  border-radius: 5px;
  margin: 0 auto;
  padding: 0px 10px;
  width: 940px;
}
#footer .links {
  padding-top: 15px;
  text-align: center;
}
.footer-link a {
  padding: 0 6px;
}
.footer-link:after {
  content: "|";
}
.footer-link.copyright:after,
.footer-link:last-child:after {
  content: '';
}
#user_panel {
  background-color: #EEE;
  border-bottom: 2px solid #DF9300;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 2px solid #DF9300;
  border-right: 2px solid #DF9300;
  margin-bottom: 10px;
  margin-left: -482px;
  padding: 5px 0;
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 999;
  box-shadow: 0px 1px 5px #555;
}
#user_panel .user a {
  line-height: 24px;
  text-decoration: none;
}
#user_panel .user a:hover {
  text-decoration: underline;
}
#user_panel.dev {
  top: 45px;
}
.welcome_banner {
  background-color: #EEE;
  border: 2px solid #DF9300;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: none;
  font-size: 18px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  width: 940px;
}
#stickyfooter-wrapper {
  background: url("/img/sendage-background-2200x1130.png") no-repeat center -15px #191917;
  height: auto !important;
  height: 100%;
  margin: 0 auto -40px;
  min-height: 100%;
}
#stickyfooter-push,
#footer {
  height: 40px;
}
.withSidebarLeft {
  border-right: 1px solid #CCC;
  float: left;
  margin-right: 15px;
  min-height: 250px;
  padding-right: 9px;
  width: 615px;
}
.withSidebarRight {
  float: left;
  margin-right: 0px;
  width: 300px;
}
.biginfo {
  color: #666666;
  font-size: 18px;
  line-height: 24px;
}
#welcome_message li,
ol.listed > li,
ul.listed > li {
  margin-left: 30px;
  margin-top: 8px;
}
#welcome_message ul li,
ul.listed > li {
  list-style-type: disc;
}
#welcome_message ol li,
ol.listed > li {
  list-style-type: decimal;
}
#welcome_message {
  margin-bottom: 20px;
}
#welcome_message .image {
  border: 2px solid #DF9300;
  border-radius: 10px;
  margin: 10px 0 20px 20px;
}
#welcome_facebook_feed {
  background-image: url("/img/welcome/facebook_feed.png");
  height: 306px;
  width: 506px;
}
#welcome_8a {
  background-image: url("/img/welcome/add_another_send_8a.png");
  height: 296px;
  width: 505px;
}
#welcome_send {
  background-image: url("/img/welcome/send_climb.png");
  height: 572px;
  width: 422px;
}
.welcome-banner .image {
  background-image: url("/img/welcome_banner_75.png");
  height: 75px;
  margin: 0 auto;
}
.welcome-banner h1 {
  font-size: 22px;
  padding-top: 0;
}
.welcome-banner p {
  font-size: 14px;
  text-align: justify;
}
.welcome-banner li {
  display: none;
}
.welcome-banner.intro li.intro {
  display: block;
}
.welcome-banner.plan li.plan {
  display: block;
}
.welcome-banner.plan .image {
  width: 100px;
}
.welcome-banner.list li.list {
  display: block;
}
.welcome-banner.list .image {
  background-position: -104px top;
  width: 65px;
}
.welcome-banner.accomplish li.accomplish {
  display: block;
}
.welcome-banner.accomplish .image {
  background-position: -170px top;
  width: 75px;
}
.welcome-banner.share li.share {
  display: block;
}
.welcome-banner.share .image {
  background-position: -245px top;
  width: 83px;
}
.welcome-banner-dots-container {
  height: 10px;
  margin: 0 auto 20px auto;
}
.welcome-banner-dots {
  margin: 0 auto;
  text-align: center;
  width: 100px;
}
.welcome-banner-dots a {
  border: 1px solid #DF9300;
  border-radius: 4px;
  display: inline-block;
  height: 8px;
  margin: 0 3px;
  width: 8px;
}
.welcome-banner-dots a.active {
  background: #DF9300;
}
a {
  color: inherit;
  text-decoration: underline;
}
li {
  margin: 3px 0;
}
strong {
  color: #DF9300;
  font-weight: bold;
}
strong.orange {
  color: #FF5C00;
}
img,
map {
  border: none;
}
ul {
  padding: 0;
}
li {
  list-style: none;
}
h1,
h2,
h3,
h4 {
  color: #DF9300;
  font-weight: bold;
  padding: 15px 0 5px 0;
}
h1.top,
h2.top,
h3.top,
h4.top {
  padding-top: 0px;
}
h1 {
  font-size: 20px;
}
h2 {
  font-size: 16px;
}
h3 {
  font-size: 16px;
}
h4 {
  font-size: 14px;
}
a.highlight-sel,
.highlight {
  color: #DF9300;
}
a.highlight-sel {
  text-decoration: none;
  cursor: default;
}
.row {
  margin: 5px 0;
}
.col {
  float: left;
  margin-right: 10px;
}
.col.last {
  margin-right: 0px;
}
.colRight {
  float: right;
  margin-left: 10px;
}
.colRight.last {
  margin-left: 0px;
}
.colLast {
  float: left;
  margin-right: 0px;
}
.colRightRight {
  float: right;
  margin-right: 10px;
}
.colbreak {
  clear: both;
}
.clear {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.clearfix:after {
  clear: both;
}
input,
select,
textarea,
.bordered {
  border: 1px solid #DF9300;
  border-radius: 5px;
}
input,
textarea {
  padding: 5px;
}
select {
  height: 29px;
  /* Without the height set, Chrome fails. It doesn't seem to understand padding */
  padding: 5px 3px;
}
input.nostyle {
  border: none;
  padding: 0;
}
.profilepic {
  /*border: 1px solid #999;*/
  border-radius: 3px;
  display: block;
  height: 50px;
  overflow: hidden;
  width: 50px;
}
.profilepic:hover {
  /*border: 1px solid @yellow;*/
}
.profilepic.small {
  height: 24px;
  width: 24px;
}
.profilepic.medium {
  height: 40px;
  width: 40px;
}
.profilepic.inprogress {
  background: url("/img/wait_spinner.gif") center center no-repeat;
}
.profilepic img {
  height: inherit;
  width: inherit;
}
#climbs {
  padding: 5px 20px 5px 5px;
  /*width: 274px;*/
  /* 300 - 5 - 25 - 1 */
}
.empty-sendlist {
  background-color: #e6e6e6;
  border-radius: 5px;
  color: #666666;
  line-height: 20px;
  padding: 10px;
  text-align: center;
  margin-top: 23px;
  box-shadow: inset 0px 1px 5px #666;
}
.empty-sendlist label {
  display: block;
  font-weight: bold;
}
#sendlistContainer {
  height: 238px;
  /* 250 - 5 - 5 - 2 */
  overflow: auto;
  padding: 5px;
  width: 288px;
  /* 300 - 5 - 5 - 2*/
}
#sendlist-search {
  width: 230px;
}
.sendlist-delete-confirm {
  margin-right: 3px;
  position: absolute;
  right: 0px;
  text-align: right;
  top: 2px;
  width: 100px;
}
a.sendlist-edit-name {
  font-size: 14px;
  font-weight: normal;
}
#sendlist_info {
  width: 400px;
}
#sendlist_info * {
  box-sizing: border-box;
}
.viewing-editing-sendlist #sendlist_info .title,
.viewing-editing-sendlist #sendlist_info .description {
  display: none;
}
.viewing-editing-sendlist #sendlist_info .form {
  display: block;
}
#sendlist_info .form {
  display: none;
  margin-bottom: 5px;
  text-align: right;
}
#sendlist_info .form input[type=text],
#sendlist_info .form textarea {
  display: inline-block;
  margin-bottom: 5px;
  width: 100%;
}
#sendlist_info .form input[type=submit] {
  line-height: 18px;
  padding: 4px 12px;
  text-align: right;
}
#sendlist_info .form input[type=text] {
  position: relative;
}
#sendlist_info .form input[type=text].error {
  border-color: red;
}
#sendlist_info .form input[type=text].error + .error-message {
  display: inline-block;
}
#sendlist_info .form .name {
  position: relative;
}
#sendlist_info .form .error-message {
  color: red;
  display: none;
  padding: 6px;
  position: absolute;
  right: 0;
  top: 0;
}
#sendlist_edit_links {
  color: #666666;
  text-align: center;
  width: 123px;
}
#sendlist_edit_links .ui-button {
  width: 100%;
}
#sendlist_edit_links .edit-list,
#sendlist_edit_links .save-list {
  margin-bottom: 8px;
}
#sendlist_edit_links .save-list {
  display: none;
}
#sendlist_edit_links .share-list {
  display: none;
}
.viewing-editing-sendlist #sendlist_edit_links .edit-list {
  display: none;
}
.viewing-editing-sendlist #sendlist_edit_links .save-list {
  display: inline-block;
}
.pending-sendlist.viewing-editing-sendlist #sendlist_edit_links .delete-list {
  display: none;
}
.pending-sendlist.viewing-editing-sendlist.facebook-user-logged-in #sendlist_edit_links .share-list {
  display: block;
}
.wait-spinner-container {
  display: inline;
  margin-left: 5px;
  position: relative;
  top: 3px;
}
.wait-icon,
.error-icon {
  display: inline-block;
  height: 16px;
  margin-left: 5px;
  position: relative;
  width: 16px;
}
.wait-icon {
  background-image: url("/img/wait_spinner.gif");
}
.error-icon {
  background-image: url("/img/exclamation-16x16.png");
}
p,
section,
.prompt {
  color: #666666;
}
.indented {
  padding-left: 5px;
}
#action-buttons {
  margin: 10px 0;
}
#action-buttons button {
  margin-left: 5px;
}
ul.home-feed {
  border-top: 1px solid #CCC;
}
ul.home-feed li {
  border-bottom: 1px solid #CCC;
  padding: 4px 0;
}
.social_signin_button {
  background-image: url("/img/social_signin.png");
  cursor: pointer;
  display: block;
  height: 29px;
  margin: 20px auto;
  width: 162px;
}
.social_signin_button.google {
  background-position: left 29px;
}
.social_signin_button.facebook {
  background-position: left 145px;
}
.signin_options {
  text-align: center;
}
.signin_options .faq {
  border-top: 1px solid #ccc;
  margin: 30px 0;
  text-align: left;
}
.signin_options .notes {
  color: #888;
  padding: 20px;
}
/********** new climb dialog ***************/
#newclimbdialog input[type=text] {
  width: 215px;
}
#newclimbdialog .create-grade {
  width: 80px;
}
#newclimbdialog h3 {
  padding-top: 5px;
}
#newclimbform {
  margin-top: 15px;
}
/*********************************************/
#advertising p {
  margin: 1em 0;
}
#advertising_payment p {
  margin: 0.75em 0;
}
#advertising_payment li {
  color: #666666;
  list-style: disc;
}
#advertising_payment .plan {
  display: none;
}
#advertising_payment #planpicker {
  margin-right: 30px;
}
#advertising_payment #flashMessage {
  border: 1px solid red;
  color: red;
  display: inline-block;
  font-size: 1.6em;
  margin: 10px;
  padding: 10px;
}
#advertising_payment input[type=file] {
  border: none;
  padding: 2px;
}
#advertising_payment input[type=text] {
  width: 200px;
}
#advertising_payment label {
  display: inline-block;
  text-align: right;
  margin-right: 10px;
  width: 60px;
}
#advertising_payment .checkout {
  margin-bottom: 30px;
  margin-top: 30px;
}
#advertising_payment .checkout .disabled {
  margin-bottom: -3px;
}
#advertising_payment .checkout .terms {
  color: #666666;
  margin-left: -16px;
}
/* SEARCH */
#searchResults {
  margin-top: 10px;
  margin-bottom: 20px;
}
#searchResults ul {
  border-top: 1px solid #888;
}
#searchResults ul .ui-button-text {
  padding: 0;
}
#searchResults ul.empty {
  border: none;
}
#searchResults li {
  border-bottom: 1px solid #888;
  padding: 3px 0;
}
#searchResults li div.type {
  min-height: 20px;
  width: 20px;
}
#searchResults .climb {
  width: 165px;
}
#searchResults .grade {
  width: 40px;
}
#searchResults .sends {
  width: 25px;
  text-align: right;
}
#searchResults button.ui-button-icon-only {
  height: 16px;
  width: 16px;
}
#searchResults .paging button.ui-button-icon-only {
  width: 24px;
}
#searchResults .paging .ui-button-text {
  padding: 0;
}
select.search-grade {
  width: 70px;
}
form.search {
  margin-bottom: 10px;
}
form.search div.prompt {
  margin-left: 5px;
}
#user_panel form.search {
  margin-bottom: 0;
}
#user_panel form.search input[name=q] {
  width: 250px;
}
#qq_upload_button {
  width: 226px;
}
#sendlist_media_uploader button,
#media_uploader button {
  line-height: 16px;
  padding: 4px 12px 4px 35px;
}
#sendlist_media_uploader button .ui-button-icon-primary,
#media_uploader button .ui-button-icon-primary {
  left: 12px;
}
#sendlist_media_uploader button span,
#media_uploader button span {
  /*display: inline; // not sure why i'm removing this. It's to fix the header search button, but could break something else??? */
  font-size: inherit;
  line-height: inherit;
  padding: 0;
}
input.with-button {
  border-right: none;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  line-height: normal;
}
button.with-input,
input.with-input {
  line-height: normal;
  padding: 5px 12px;
  border-color: #DF9300;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
button.with-input .ui-button-text,
input.with-input .ui-button-text,
button.with-input span,
input.with-input span {
  /*display: inline; // not sure why i'm removing this. It's to fix the header search button, but could break something else??? */
  font-size: inherit;
  line-height: inherit;
  padding: 0;
}
ul.search-results {
  margin-left: 20px;
}
ul.search-results li {
  margin-bottom: 12px;
}
#sendlist_media_uploader,
#media_uploader {
  margin-top: 20px;
}
#sendlist_media_uploader.top,
#media_uploader.top {
  margin-top: 0px;
}
#sendlist_media_upload_preview {
  margin-top: 10px;
}
#sendlist_media_upload_preview .sendage-media-item {
  cursor: default;
}
/* SENDLIST */
ul.ui-sendlist-border {
  border-top: 1px solid #888;
}
ul.ui-sendlist {
  padding: 3px;
}
ul.ui-sendlist li {
  border-bottom: 1px solid #888;
  padding: 2px 0;
}
ul.ui-sendlist li div.info {
  width: 170px;
}
ul.ui-sendlist li div.grade {
  width: 45px;
}
ul.ui-sendlist li div.comments {
  width: 210px;
  color: #666666;
}
ul.ui-sendlist li div.sendlist-action-buttons {
  color: #DF9300;
  font-size: 16px;
  text-align: center;
}
ul.ui-sendlist li div.sendlist-action-buttons i.fa:hover {
  color: #FF5C00;
}
ul.ui-sendlist li div.rating {
  margin-left: 0px;
}
ul.ui-sendlist li .trophy {
  font-size: 16px;
  text-align: center;
  min-height: 5px;
  width: 32px;
}
ul.ui-sendlist li .ui-sendlist-climb-trophy.editable sup {
  display: block;
  margin-top: -2px;
}
ul.ui-sendlist li .ui-button {
  display: block;
  margin-bottom: 2px;
}
ul.ui-sendlist li .remove_option {
  display: none;
  height: 20px;
  position: relative;
}
.ui-sendlist-climb-trophy.editable {
  color: #888;
  cursor: pointer;
  text-align: center;
}
#home_feed ul > li .instagram-user img {
  width: 50px;
}
#home_feed ul > li .instagram img {
  max-width: 100%;
  width: auto;
}
#home_feed ul > li .instagram p {
  margin: 1em 0;
}
#home_feed ul > li .instagram p a {
  color: #DF9300;
  text-decoration: none;
}
.feed-item {
  width: 560px;
}
.feed-send-trophy {
  text-align: center;
  width: 50px;
  font-size: 25px;
}
.feed-send-description {
  margin-top: 5px;
  width: 490px;
}
.feed-time {
  margin-top: 5px;
}
.feed-list-description {
  margin-top: 5px;
  width: 560px;
}
.ui-sendlist-climb-label {
  font-weight: bold;
}
.ui-sendlist-climb-location {
  color: #DF9300;
  display: inline-block;
  font-size: 11px;
  font-style: italic;
  margin-bottom: 2px;
}
#sendage-dialog .trophy-selector {
  margin: 0 auto 15px;
  text-align: center;
}
#sendage-dialog .trophy-selector .trophy-count {
  display: inline-block;
  margin: 0 5px;
  width: 52px;
}
#sendage-dialog .trophy-selector .trophy,
#sendage-dialog .trophy-selector .repeat,
#sendage-dialog .trophy-selector .project {
  font-size: 26px;
}
#sendage-dialog .rating-container {
  width: 90px;
}
#sendage-dialog-datepicker {
  width: 240px;
}
.sendage-dialog-beta,
.sendage-dialog-comment {
  height: 50px;
  width: 360px;
}
.trophies {
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: 0px 1px 5px #888;
}
.trophies.bordered {
  border: 1px solid #DF9300;
}
.trophies.selected {
  border: 3px solid #DF9300;
  padding: 3px;
}
.trophies.hover {
  border: 1px solid #DF9300;
  padding: 5px;
}
span.error,
div.error,
#rating_error {
  color: red;
}
.error400 .suggestions {
  margin-top: 20px;
}
.error400 ul li {
  margin-bottom: 5px;
}
.setting {
  border-bottom: 1px solid #CCC;
  margin-top: 15px;
  padding-bottom: 10px;
}
.setting.noborder {
  border: none;
}
.setting .sponsor-list {
  margin-top: 5px;
  width: 805px;
}
.setting .sponsor-list .item {
  margin-top: 2px;
}
.setting .sponsor-list .item label {
  cursor: pointer;
  display: inline-block;
  padding: 0 5px 5px 0;
}
.setting .sponsor-list .item label input {
  display: inline-block;
  margin-top: 5px;
  vertical-align: top;
}
.setting .other-sponsors {
  margin-top: 10px;
}
.settings-label {
  width: 125px;
}
.settings-desc {
  width: 485px;
}
.settings-value {
  text-align: right;
  width: 280px;
}
.settings-value input[type=text] {
  width: 270px;
}
.settings-value textarea {
  width: 270px;
  height: 60px;
}
sup,
sub {
  font-size: 11px;
}
sup.super {
  vertical-align: super;
}
sub.sub {
  vertical-align: sub;
}
ul.active-areas li,
ul.popular-climbs li {
  color: #DF9300;
  list-style: none;
  margin-top: 2px;
  padding-left: 10px;
}
ul.listed-sendlists li {
  margin-top: 8px;
}
/* Used on Area page and on Climb page for dialog boxes */
.edit-info-form div.input {
  margin-top: 10px;
}
.edit-info-form select,
.edit-info-form textarea,
.edit-info-form input[type=text] {
  box-sizing: border-box;
  width: 100%;
}
.edit-info-form textarea {
  height: 90px;
}
.edit-info-form label {
  color: #DF9300;
  display: block;
  font-weight: bold;
  padding-left: 5px;
}
#edit_area_form .inputs,
#edit_area_form .map {
  box-sizing: border-box;
  margin: 0;
}
#edit_area_form .inputs {
  padding-right: 10px;
  width: 40%;
}
#edit_area_form .map {
  padding-left: 10px;
  width: 60%;
}
#edit_area_form .latlon {
  margin-top: 13px;
}
.inset_panel {
  background-color: #e6e6e6;
  border-radius: 5px;
  padding: 15px 10px;
  margin: 0 0 15px 0px;
  box-shadow: inset 0px 1px 5px #666;
}
.inset_panel h1 {
  padding-top: 0px;
  padding-bottom: 10px;
}
.sidebar-ad-container {
  height: 250px;
  margin-bottom: 15px;
  overflow: hidden;
  position: relative;
  width: 300px;
}
.sidebar-ad-container .sidebar-ad {
  cursor: pointer;
  display: block;
  height: 250px;
  left: 0;
  width: 300px;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.5s;
  z-index: 1;
}
.sidebar-ad-container .sidebar-ad.active {
  opacity: 1;
  z-index: 2;
}
.sidebar_panel {
  background-color: #e9e9e9;
  border-radius: 5px;
  display: block;
  padding: 15px 10px;
  margin: 0 0 15px 0px;
  width: 280px;
  box-shadow: inset 0px 1px 5px #666;
}
.sidebar_panel h1 {
  padding-top: 0px;
  padding-bottom: 10px;
}
.sidebar_panel.anchored_image {
  cursor: pointer;
  padding-bottom: 0;
  padding-top: 0;
}
.sidebar_panel.centered {
  text-align: center;
}
.sidebar_panel .sidebar-ad {
  height: 250px;
  width: 300px;
}
.sidebar_imagead {
  display: block;
  margin: 15px 0;
}
.adsense {
  margin: 15px 0;
}
.adsense > div {
  color: #666666;
  text-align: right;
}
.media_panel {
  background-color: #e6e6e6;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
  box-shadow: inset 0px 1px 5px #666;
}
.media_panel #media_uploader {
  margin-top: 0px;
}
.pages_home.grid_4 .sendage-media {
  margin: 3px 3px;
}
.pages_home.grid_4 .sendage-media .sendage-media-item {
  width: 50px;
  height: 50px;
}
.climbs_view h3 {
  margin-top: 20px;
  padding-top: 0;
}
.climbs_view .send_info {
  text-align: center;
}
.climbs_view .send_info h3 {
  padding-bottom: 0px;
  padding-top: 0px;
}
.climbs_view .send_info .trophies {
  cursor: default;
}
.climbs_view .climb-title .areas {
  color: #666666;
}
.climbs_view .climb-info .climb-title {
  float: left;
}
.climbs_view .climb-info .climb-meta {
  clear: both;
  color: #666666;
}
.climbs_view .climb-info .climb-rating {
  float: right;
  padding-top: 3px;
}
.climbs_view ul.climb-beta li {
  margin-top: 8px;
}
.climbs_view ul.climb-beta li .comments {
  width: 570px;
}
.climbs_view ul.recent-sendage li {
  margin-top: 8px;
}
.climbs_view ul.recent-sendage li .trophy {
  text-align: center;
  width: 40px;
}
.climbs_view ul.recent-sendage li .title {
  width: 105px;
}
.climbs_view ul.recent-sendage li .title .fa-star-rating {
  font-size: 12px;
}
.climbs_view ul.recent-sendage li .comments {
  color: #666666;
  width: 390px;
}
.climbs_view ul.recent-sendage li .trophy.fa-stack {
  font-size: 16px;
}
.climbs_view .logged_in_buttons {
  display: none;
  margin-top: 10px;
}
.climbs_view .logged_in_buttons button,
.climbs_view .logged_in_buttons a {
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 20px;
}
.climbs_view .logged_in_buttons button.major,
.climbs_view .logged_in_buttons a.major {
  text-align: left;
  width: 200px;
  margin-top: 0px;
}
.climbs_view .climb_media_panel {
  display: none;
}
.climbs_view .sendage-media {
  margin: 3px 3px;
}
.climbs_view .sendage-media .sendage-media-item {
  width: 50px;
  height: 50px;
}
.climbs_view .sidebar_panel.climb_overview_image {
  background-position: center center;
  background-size: cover;
  height: 196px;
}
.user_climbs_view .title {
  width: 440px;
}
.user_climbs_view .comments {
  margin-left: 60px;
}
.user_climbs_view .repeats {
  color: #666666;
  width: 100%;
}
.user_climbs_view .repeats th {
  color: #FF5C00;
}
.user_climbs_view .repeats th,
.user_climbs_view .repeats td {
  padding: 2px;
}
.user_climbs_view .repeats .comments {
  margin: 0;
}
.user_climbs_view .repeats .cnt {
  text-align: center;
}
.user_climbs_view .repeats .remove {
  text-align: right;
}
.editable-content {
  box-sizing: border-box;
  position: relative;
}
.editable-content form,
.editable-content textarea {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
.editable-content textarea {
  padding-right: 75px;
}
.editable-content button,
.editable-content input[type=submit] {
  background-color: #DF9300;
  border-color: #DF9300;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  bottom: 0;
  box-shadow: none;
  color: white;
  cursor: pointer;
  margin-right: 0;
  position: absolute;
  right: 0;
}
.climbs_view .climb-info > .description,
.areas_view > .description {
  border-bottom: 1px solid #ccc;
  margin: 10px 0 20px;
  padding-bottom: 10px;
}
.climbs_view .climb-info > .description h4,
.areas_view > .description h4 {
  padding: 0 0 2px;
  margin-top: 10px;
}
.climbs_view .climb-info > .description p,
.areas_view > .description p {
  margin: 5px 0;
}
.climbs_view .climb-info > .description .edit-content,
.areas_view > .description .edit-content,
.climbs_view .climb-info > .description .under-review,
.areas_view > .description .under-review {
  color: #666666;
  float: right;
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
}
.climbs_view .climb-info > .description .editable-content,
.areas_view > .description .editable-content {
  display: none;
  height: 70px;
  margin: 0 0 20px;
}
.climbs_view .climb-info > .description .editable-content.active,
.areas_view > .description .editable-content.active {
  display: block;
}
.area-tree > ul > li > a {
  color: #DF9300;
  display: inline-block;
  font-weight: bold;
  margin-top: 20px;
}
.area-tree > ul > li ul {
  padding-left: 20px;
}
.area-tree-continued ul {
  margin-top: 40px;
  padding-left: 20px;
}
.areas_view .sendage-media {
  margin: 3px 3px;
}
.areas_view .sendage-media .sendage-media-item {
  width: 50px;
  height: 50px;
}
.areas_view .title {
  width: 445px;
}
.areas_view .area_parent {
  color: #666666;
  margin-bottom: 10px;
}
.areas_view .area_children {
  color: #404040;
  margin: 20px 0 10px 0;
  box-sizing: border-box;
  margin: 0 -10px;
}
.areas_view .area_children h4 {
  padding-left: 10px;
}
.areas_view .area_children .group {
  box-sizing: border-box;
  float: left;
  padding: 0 10px;
  width: 33.3333%;
}
.areas_view .area_children .group label {
  display: inline-block;
  font-weight: bold;
  padding-bottom: 4px;
}
.areas_view .area_children .group div {
  margin-bottom: 4px;
}
.areas_view .area_children .group div.weight-5 {
  font-weight: bold;
}
.areas_view .area_children .group div.weight-4 {
  color: #666666;
  font-weight: bold;
}
.areas_view .area_children .group div.weight-3 {
  color: #737373;
}
.areas_view .area_children .group div.weight-2 {
  color: #8c8c8c;
}
.areas_view .area_children .group div.weight-1,
.areas_view .area_children .group div.weight-0 {
  color: #999999;
}
.areas_view .logged_in_buttons {
  margin-top: 20px;
}
.areas_view .logged_in_buttons button,
.areas_view .logged_in_buttons a {
  display: inline-block;
  margin-bottom: 5px;
  text-align: left;
  width: 230px;
}
.areas_view .logged_in_buttons button.major,
.areas_view .logged_in_buttons a.major {
  text-align: left;
  width: 200px;
  margin-top: 0px;
}
.sendlist_create #sendlist_name,
.sendlist_create #sendlist_description {
  box-sizing: border-box;
  width: 100%;
}
.sendlist_create #sendlist-search {
  width: 240px;
}
.sendlist_create .fixed-filters {
  width: 160px;
}
.sendlist_create .filters {
  height: 25px;
  margin-top: 5px;
}
.sendlist_create .form.prompt {
  margin-top: 10px;
  padding-left: 6px;
}
.sendlist_create .list_details {
  margin-bottom: 20px;
}
.sendlist_create .list_details .error {
  margin: 10px 5px 0 0;
}
.sendlist_create .list_climbs {
  border-radius: 5px;
  min-height: 530px;
  box-shadow: inset 0 0 10px #AAA;
}
.sendlist_create .list_climbs .empty-list-message {
  padding: 15px;
  text-align: center;
}
.sendlist_create .climb_count {
  color: #666666;
  text-align: right;
  line-height: 28px;
}
.sendlist_create ul.ui-sendlist-border {
  border-top: none;
}
.sendlist_create ul.ui-sendlist li {
  padding-left: 5px;
}
.sendlist_create ul.ui-sendlist li .ui-sendlist-makecomment,
.sendlist_create ul.ui-sendlist li div.comments,
.sendlist_create ul.ui-sendlist li div.rating {
  display: none;
}
.sendlist_create ul.ui-sendlist li div.info {
  width: 300px;
}
.sendlist_create ul.ui-sendlist li div.grade {
  width: 40px;
}
.sendlist_create ul.ui-sendlist li div.sendlist-action-buttons {
  margin-right: 10px;
}
/* override star rating disable */
#sendage-dialog .rating-cancel {
  display: none !important;
}
/* override jquery UI */
.ui-datepicker {
  border: 1px solid #DF9300;
  display: none;
  position: absolute;
  width: 205px;
}
.ui-widget-content a {
  color: inherit;
}
.ui-button-text {
  font-size: 14px;
}
table td {
  padding-right: 10px;
}
td.right {
  text-align: right;
}
.ui-button {
  box-shadow: 1px 1px 2px #666;
}
.ui-button.with-input {
  box-shadow: none;
}
.ui-autocomplete {
  *height: 200px;
  max-height: 200px;
  overflow: auto;
}
.ui-autocomplete .ui-menu-item .climb {
  color: black;
}
.ui-autocomplete .ui-menu-item .grade,
.ui-autocomplete .ui-menu-item .num-sends {
  color: #666666;
}
.ui-autocomplete .ui-menu-item .area {
  color: #666666;
  font-size: 11px;
  font-style: italic;
}
.ui-autocomplete .ui-state-hover {
  background: none;
  background-color: rgba(255, 209, 121, 0.7);
  border: 1px solid transparent;
  color: black;
}
.ui-slider .ui-slider-handle {
  z-index: 1;
}
#qq_upload_button .ui-button {
  box-shadow: 0 0 0 #fff;
}
.ui-state-default.major,
.ui-widget-content .ui-state-default.major,
.ui-widget-header .ui-state-default.major {
  border: 1px solid #993700;
  background: #FF5C00;
}
.ui-state-default.major.ui-state-hover,
.ui-widget-content .ui-state-default.major.ui-state-hover,
.ui-widget-header .ui-state-default.major.ui-state-hover {
  border: 1px solid #FF5C00;
  background: #ff7d33;
}
.ui-state-default.major.ui-state-active,
.ui-widget-content .ui-state-default.major.ui-state-active,
.ui-widget-header .ui-state-default.major.ui-state-active {
  border: 1px solid #FF5C00;
  background: #cc4a00;
}
/* Override Disqus */
#disqus_thread {
  color: #666666;
}
#disqus_thread h1,
#disqus_thread h2,
#disqus_thread h3,
#disqus_thread h4 {
  padding: 0;
}
/*#dsq-subscribe,*/
#dsq-global-toolbar {
  display: none !important;
}
#dsq-footer {
  margin: 5px 0 0 0 !important;
}
#dsq-sort-by {
  float: none !important;
}
#dsq-content h3,
#dsq-reply h3 {
  visibility: hidden;
  margin: 0 !important;
}
#disqus_thread #dsq-content .dsq-pagination {
  padding: 5px;
  margin: 5px 0;
}
/* Users Page */
.trophy-large,
.trophy-small {
  background: url("/img/sendage-trophy-sprite-x3.png") no-repeat;
  display: inline-block;
}
.trophy-large {
  height: 40px;
  width: 32px;
}
.trophy-large.white {
  background-position: 0 0;
}
.trophy-large.redpoint,
.trophy-large.bronze {
  background-position: -32px 0px;
}
.trophy-large.redpoint.fa,
.trophy-large.bronze.fa {
  background-position: -32px -93px;
}
.trophy-large.flash,
.trophy-large.silver {
  background-position: -64px 0px;
}
.trophy-large.flash.fa,
.trophy-large.silver.fa {
  background-position: -64px -93px;
}
.trophy-large.onsight,
.trophy-large.gold {
  background-position: -96px 0px;
}
.trophy-large.onsight.fa,
.trophy-large.gold.fa {
  background-position: -96px -93px;
}
.trophy-small {
  height: 30px;
  width: 24px;
}
.trophy-small.project,
.trophy-small.white {
  background-position: 0 -41px;
}
.trophy-small.redpoint,
.trophy-small.bronze {
  background-position: -24px -41px;
}
.trophy-small.redpoint.fa,
.trophy-small.bronze.fa {
  background-position: -24px -134px;
}
.trophy-small.flash,
.trophy-small.silver {
  background-position: -48px -41px;
}
.trophy-small.flash.fa,
.trophy-small.silver.fa {
  background-position: -48px -134px;
}
.trophy-small.onsight,
.trophy-small.gold {
  background-position: -72px -41px;
}
.trophy-small.onsight.fa,
.trophy-small.gold.fa {
  background-position: -72px -134px;
}
.trophy-show {
  margin: 0 auto 10px auto;
  width: 248px;
}
.trophy-show .trophy {
  font-size: 30px;
}
.trophy-count {
  background-color: #EEE;
  padding: 5px;
  text-align: center;
  width: 64px;
}
.trophy-count h1 {
  padding: 0px;
}
.trophy-count .trophy.fa-stack {
  font-size: 30px;
}
.iesucks {
  position: fixed;
  bottom: 0px;
  height: 115px;
  background-color: white;
  width: 100%;
  text-align: center;
  padding: 10px;
  border-top: 5px solid red;
}
.js-required {
  border: 5px solid red;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}
.dev-banner {
  background-color: white;
  border-bottom: 5px solid red;
  border-top: 5px solid red;
  font-size: 20px;
  padding: 5px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 998;
}
.profile-info label {
  color: #666666;
  display: inline-table;
  line-height: 20px;
  padding-right: 3px;
  vertical-align: middle;
}
.profile-info label.header {
  line-height: 28px;
}
.profile-bio {
  color: #666666;
  margin-top: 5px;
  padding-left: 59px;
}
.profile-bio.loggedin {
  width: 365px;
}
.user-links a.protocol-anchor {
  font-size: 20px;
  outline: none;
}
.user-links a.protocol-anchor .fa-rss-square {
  color: #FF5C00;
}
.user-links a.protocol-anchor .fa-facebook-square {
  color: #3D5595;
}
.user-links a.protocol-anchor .fa-twitter-square {
  color: #1BB7EA;
}
.user-links a.protocol-anchor .fa-instagram {
  color: #FF295A;
}
.profile-icon {
  background: url("/img/profile_icons_20.png") center center no-repeat;
  display: inline-table;
  height: 20px;
  margin-right: 3px;
  vertical-align: middle;
  width: 20px;
}
.profile-icon.sponsor {
  background: url("/img/sponsor_icons_20.png") center center no-repeat;
}
.profile-icon.wide {
  width: 40px;
}
.profile-icon.blog {
  background-position: 0px 0px;
}
.profile-icon.twitter {
  background-position: 0px -20px;
}
.profile-icon.facebook {
  background-position: 0px -40px;
}
.profile-icon.pos1x1 {
  background-position: 0px 0px;
}
.profile-icon.pos1x2 {
  background-position: 0px -20px;
}
.profile-icon.pos1x3 {
  background-position: 0px -40px;
}
.profile-icon.pos1x4 {
  background-position: 0px -60px;
}
.profile-icon.pos1x5 {
  background-position: 0px -80px;
}
.profile-icon.pos1x6 {
  background-position: 0px -100px;
}
.profile-icon.pos1x7 {
  background-position: 0px -120px;
}
.profile-icon.pos1x8 {
  background-position: 0px -140px;
}
.profile-icon.pos1x9 {
  background-position: 0px -160px;
}
.profile-icon.pos1x10 {
  background-position: 0px -180px;
}
.profile-icon.pos2x1 {
  background-position: -20px 0px;
}
.profile-icon.pos2x2 {
  background-position: -20px -20px;
}
.profile-icon.pos2x3 {
  background-position: -20px -40px;
}
.profile-icon.pos2x4 {
  background-position: -20px -60px;
}
.profile-icon.pos2x5 {
  background-position: -20px -80px;
}
.profile-icon.pos2x6 {
  background-position: -20px -100px;
}
.profile-icon.pos2x7 {
  background-position: -20px -120px;
}
.profile-icon.pos2x8 {
  background-position: -20px -140px;
}
.profile-icon.pos2x9 {
  background-position: -20px -160px;
}
.profile-icon.pos2x10 {
  background-position: -20px -180px;
}
.profile-icon.pos3x1 {
  background-position: -40px 0px;
}
.profile-icon.pos3x2 {
  background-position: -40px -20px;
}
.profile-icon.pos3x3 {
  background-position: -40px -40px;
}
.profile-icon.pos3x4 {
  background-position: -40px -60px;
}
.profile-icon.pos3x5 {
  background-position: -40px -80px;
}
.profile-icon.pos3x6 {
  background-position: -40px -100px;
}
.profile-icon.pos3x7 {
  background-position: -40px -120px;
}
.profile-icon.pos3x8 {
  background-position: -40px -140px;
}
.profile-icon.pos3x9 {
  background-position: -40px -160px;
}
.profile-icon.pos3x10 {
  background-position: -40px -180px;
}
.profile-icon.pos4x1 {
  background-position: -60px 0px;
}
.profile-icon.pos4x2 {
  background-position: -60px -20px;
}
.profile-icon.pos4x3 {
  background-position: -60px -40px;
}
.profile-icon.pos4x4 {
  background-position: -60px -60px;
}
.profile-icon.pos4x5 {
  background-position: -60px -80px;
}
.profile-icon.pos4x6 {
  background-position: -60px -100px;
}
.profile-icon.pos4x7 {
  background-position: -60px -120px;
}
.profile-icon.pos4x8 {
  background-position: -60px -140px;
}
.profile-icon.pos4x9 {
  background-position: -60px -160px;
}
.profile-icon.pos4x10 {
  background-position: -60px -180px;
}
/* Admin */
table.admin-datatable {
  border: 1px solid black;
  width: 100%;
}
table.admin-datatable th {
  border: 1px solid black;
  color: #DF9300;
  font-weight: bold;
  padding: 10px;
}
table.admin-datatable td {
  border: 1px solid black;
  padding: 10px;
}
table.admin-datatable.admin-stats {
  margin: 20px 0 ;
}
table.admin-datatable.admin-stats th,
table.admin-datatable.admin-stats td {
  text-align: center;
}
table.admin-datatable .actions {
  min-width: 120px;
}
table.admin-datatable .actions a {
  color: #DF9300;
}
/* Media */
.sendage-media {
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
}
.sendage-media .sendage-media-item {
  background-position: center center;
  border: 2px solid #DF9300;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
  height: 75px;
  width: 75px;
}
.sendage-media .sendage-media-item.small {
  height: 45px;
  width: 45px;
}
.sendage-media .sendage-media-item.inprogress {
  background: url("/img/wait_spinner.gif") center center no-repeat;
}
.sendage-media .sendage-media-item .media-remove {
  height: 16px !important;
  padding-bottom: 4px;
  position: absolute;
  right: 0px;
  top: 1px;
  width: 16px !important;
}
.sendage-media .sendage-media-item .media-video {
  height: 16px !important;
  padding-bottom: 4px;
  position: absolute;
  right: 1px;
  bottom: 3px;
  width: 20px !important;
}
.users_view.grid_8 > div.col.jTour_exposed {
  background-color: #EEE;
  border-radius: 5px;
  margin: -10px;
  padding: 10px;
}
.users_view #grade_graph {
  display: none;
  height: 120px;
  margin: 2px auto;
  width: 270px;
}
.users_view #show_sendlists_btn {
  display: none;
  margin-top: 20px;
}
.users_view .user-details {
  margin-right: 0;
  width: 100%;
}
.users_view .profile-info {
  margin-right: 0;
  width: calc(100% - 60px);
}
.users_view.loggedin #show_sendlists_btn {
  margin-top: 10px;
}
.users_view.loggedin .user-details {
  max-width: 100%;
  width: 440px;
}
.users_view.loggedin .profile-info {
  width: 360px;
}
.users_view #mobile_sendlists_container {
  display: none;
  padding: 20px 0;
}
.users_view .profilepic-col {
  text-align: center;
}
.users_view a.follows {
  color: #666666;
  display: inline-block;
  margin: 5px 0;
}
.users_view .sidebar_panel.followees .profilepic {
  float: left;
}
.users_view .sidebar_panel.followees .latest {
  color: #666666;
  float: left;
  padding-left: 10px;
  width: calc(100% - 34px);
}
.users_view .sidebar_panel.followees .latest a {
  color: #666666;
}
.users_view .sidebar_panel.followees > div {
  margin-bottom: 6px;
}
.users_view .sidebar_panel.followers .profilepic {
  display: inline-block;
  margin-: 2px;
}
.users_view .grade-graph {
  display: none;
  color: #666666;
}
.users_view .grade-graph.col {
  margin-left: 10px;
}
.users_view .grade-graph.colRight {
  margin-right: 15px;
}
.users_view sub.grade-graph {
  margin-left: 10px;
  color: #DF9300;
}
.users_view .year-graph {
  display: none;
}
.users_view .sendage-media {
  margin: 3px 3px;
}
.users_view .sendage-media .sendage-media-item {
  width: 50px;
  height: 50px;
}
.users_view .logged_in_buttons div {
  margin-bottom: 5px;
}
.users_view .logged_in_buttons div a {
  text-align: left;
  width: 100%;
}
.users_view .logged_in_buttons div a.major {
  margin-top: 10px;
}
.users_view .karma-score,
.users_view .user-links,
.users_view .user-sponsors {
  box-sizing: border-box;
  color: #666666;
  float: left;
  margin-bottom: 10px;
  width: 38%;
}
.users_view .karma-score label,
.users_view .user-links label,
.users_view .user-sponsors label {
  display: block;
}
.users_view .karma-score .othersponsors,
.users_view .user-links .othersponsors,
.users_view .user-sponsors .othersponsors {
  font-size: 11px;
  margin-top: 3px;
}
.users_view .user-sponsors,
.users_view .user-links {
  padding-right: 15px;
}
.users_view .karma-score {
  width: 24%;
}
.users_stats #overall_chart {
  height: 50px;
  margin: 10px 0;
  width: 100px;
}
.users_stats #overall_chart .chart {
  height: 50px;
  width: 940px;
}
.users_stats .infographic {
  font-size: 32px;
}
.users_stats .infographic label {
  color: #666666;
  display: inline-block;
}
.users_stats .infographic .group {
  background-color: #eee;
  border-radius: 10px;
  display: block;
  margin: 0.3em 0;
  min-height: 8.7em;
  padding: 1em 0;
  position: relative;
  box-shadow: inset 0px 1px 5px #666;
}
.users_stats .infographic .group .climb-items,
.users_stats .infographic .group .pie {
  display: none;
  position: absolute;
  height: 240px;
  right: 1em;
  top: 1em;
  width: 480px;
}
.users_stats .infographic .group .climb-items .legend table,
.users_stats .infographic .group .pie .legend table {
  font-size: 12px !important;
}
.users_stats .infographic .group .climb-items .legend table td,
.users_stats .infographic .group .pie .legend table td {
  padding-bottom: 1px;
}
.users_stats .infographic .group .moreinfo {
  left: 4em;
  position: absolute;
  text-align: center;
  top: 4em;
  width: 150px;
}
.users_stats .infographic .group .moreinfo span {
  display: block;
  line-height: 1em;
  font-size: 2em;
}
.users_stats .infographic .group .moreinfo label {
  display: block;
  color: #666666;
  font-size: 0.4em;
  line-height: 0.2em;
}
.users_stats .infographic .group .climb-items {
  width: 550px;
}
.users_stats .infographic .group .climb-items .climb-item {
  float: left;
  font-size: 12px;
  margin: 5px 10px;
  width: 255px;
}
.users_stats .infographic .group .climb-items .climb-item .trophy {
  float: left;
  margin-right: 6px;
}
.users_stats .infographic .group .climb-items .climb-item .info {
  color: #666666;
  float: left;
  width: 225px;
}
.users_stats .infographic .group .climb-items .climb-item .info label {
  color: #DF9300;
  font-size: inherit;
  font-weight: bold;
}
.users_stats .infographic .year {
  color: #DF9300;
  display: inline;
  float: left;
  font-size: 3em;
  font-weight: bold;
  transform: rotate(-90deg) translate(-0.6em, -0.5em);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.users_stats .infographic .data {
  float: left;
  margin-left: -2.2em;
}
.users_stats .infographic .days,
.users_stats .infographic .sends {
  float: left;
  margin-top: -0.6em;
}
.users_stats .infographic .days span,
.users_stats .infographic .sends span {
  font-size: 5em;
  letter-spacing: -0.05em;
  text-align: center;
}
.users_stats .infographic .days label,
.users_stats .infographic .sends label {
  font-size: 0.6em;
  transform: rotate(-90deg) translate(2.5em, -1.8em);
}
.users_stats .infographic .days.smaller span,
.users_stats .infographic .sends.smaller span {
  font-size: 4.4em;
}
.users_stats .infographic .days {
  margin-left: -1em;
}
.users_stats .infographic .minor {
  float: left;
  margin-left: -1em;
}
.users_stats .infographic .minor div {
  float: left;
}
.users_stats .infographic .minor div span {
  font-size: 2em;
}
.users_stats .infographic .minor div label {
  font-size: 0.35em;
  transform: rotate(-90deg) translate(1.7em, -1em);
}
.users_stats .infographic .minor div.fas,
.users_stats .infographic .minor div.locations {
  cursor: pointer;
  position: relative;
}
.users_stats .infographic .minor div.fas label {
  transform: rotate(-90deg) translate(1.7em, 0em);
}
.users_stats .infographic .minor div.clear {
  float: none;
}
.users_stats .infographic .grade {
  float: left;
  line-height: 0.6em;
  padding: 0.2em 1em 0 0;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.users_stats .infographic .grade span {
  font-size: 2.5em;
}
.users_stats .infographic .grade label {
  font-size: 0.4em;
  /*letter-spacing: 0.1em;*/
}
.users_stats .infographic .grade .close_btn {
  top: -0.6em;
}
.users_stats .infographic .grade .chart {
  font-size: 16px;
  height: 50px;
  margin: -5px auto 0 auto;
  width: 100px;
}
.users_stats .infographic .close_btn {
  background: url("/img/close-button-16x16.gif");
  display: none;
  height: 16px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 16px;
}
.widget_index table th {
  color: #DF9300;
  font-weight: bold;
}
.widget_index table th,
.widget_index table td {
  padding: 5px 10px 5px 0;
  vertical-align: middle;
}
.widget_index table td input[type=text] {
  width: 155px;
}
.widget_index table td select[name=data-type],
.widget_index table td select[name=data-exclude] {
  width: 167px;
}
.widget_index table td em {
  font-weight: bold;
  text-decoration: underline;
}
.widget_index .buttons {
  margin-top: 30px;
}
.code_dialog textarea {
  font-family: courier new, monospace;
  height: 210px;
  width: 600px;
}
span.media-title-source {
  font-size: 10px;
  margin-left: 15px;
}
.media-error {
  color: red;
}
table.listed-areas {
  margin: 10px 0 20px 0;
}
.listed-areas td.label {
  width: 200px;
}
.listed-areas td.num-climbs {
  text-align: right;
}
.listed-areas th {
  color: #DF9300;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0;
}
#create_area_form label {
  color: #DF9300;
  display: block;
  margin: 10px 0 1px 0;
}
#create_area_form input[type=text] {
  width: 270px;
}
#create_area_form select {
  width: 281px;
}
#create_area_form input[type=submit] {
  margin-top: 20px;
}
#create_area_form .state_code.state,
#create_area_form .state_code.province {
  display: none;
}
ul.latest_sends .recent_send {
  width: 245px;
}
table.list-clones td {
  padding: 2px 10px 2px 0;
  vertical-align: middle;
}
.sponsored_content {
  display: none;
  margin-top: 15px;
  margin-bottom: 10px;
}
.sponsored_content iframe {
  border-radius: 5px;
}
.sponsored_content:after {
  content: "Advertisement";
  color: #666;
  display: block;
  padding: 2px;
}
/********** team ************/
.team-description p {
  margin: 8px 0;
}
.team-thumb {
  display: inline-block;
  height: 24px;
  width: 24px;
}
.team-thumb.flashed {
  background-image: url("/img/team/flashed/thumb-24.png");
}
.team-thumb.evolv-canada {
  background-image: url("/img/team/evolv/canada/thumb-24.png");
}
.fb-like {
  margin: 5px 0 5px 0;
}
/******* Twitter overrides **********/
#twtr-widget-1 .twtr-doc {
  box-shadow: inset 0px 1px 5px #666;
}
#twtr-widget-1 .twtr-doc .twtr-hd h4:before {
  content: '@';
}
#twtr-widget-1 .twtr-doc .twtr-timeline {
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  border-radius: 0 !important;
}
#home_welcome_banner .slideshow {
  height: 130px;
}
/* button hacks */
button.ui-sendlist-climb-remove {
  background: none;
  border: none;
}
button.ui-sendlist-climb-remove .ui-icon {
  background: url('/img/trash.png') no-repeat;
}
button.ui-sendlist-climb-remove.ui-state-hover .ui-icon {
  background: url('/img/trash_active.png') no-repeat;
}
a.ui-sendlist-makecomment {
  background: none;
  border: none;
}
a.ui-sendlist-makecomment .ui-icon {
  background: url('/img/comment.png') no-repeat;
}
a.ui-sendlist-makecomment.has-comments .ui-icon,
a.ui-sendlist-makecomment.ui-state-hover .ui-icon {
  background: url('/img/comment_active.png') no-repeat;
}
button.ui-sendlist-addtosendlist {
  background: none;
  border: none;
}
button.ui-sendlist-addtosendlist .ui-icon {
  background: url('/img/add-to-sendlist.png') no-repeat;
}
button.ui-sendlist-addtosendlist.ui-state-hover .ui-icon {
  background: url('/img/add-to-sendlist_active.png') no-repeat;
}
button.ui-sendlist-logsend {
  background: none;
  border: none;
}
button.ui-sendlist-logsend .ui-icon {
  background: url('/img/checkmark.png') no-repeat;
}
button.ui-sendlist-logsend.ui-state-hover .ui-icon {
  background: url('/img/checkmark_active.png') no-repeat;
}
#terms_and_conditions h1,
#privacy_policy h1 {
  padding-bottom: 1em;
}
#terms_and_conditions strong,
#privacy_policy strong {
  color: inherit;
}
#terms_and_conditions p,
#privacy_policy p {
  line-height: 1.5em;
  margin-bottom: 1.5em;
}
.user_notice {
  background-color: #FF5C00;
  border-radius: 5px;
  color: white;
  margin: 10px 0;
  padding: 10px;
  text-align: center;
  box-shadow: inset 0px 1px 5px #993700;
}
.user_notice h4 {
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  padding: 0 0 5px 0;
}
#sendlist_page_container {
  overflow: hidden;
  padding: 20px 0;
  text-align: center;
}
#sendlist_page_container > div {
  margin-left: -26px;
}
#sendlist_page_container > div img {
  vertical-align: text-bottom;
  visibility: hidden;
}
#sendlist_page_container > div label {
  cursor: pointer;
  color: #DF9300;
  font-size: 16px;
  font-weight: bold;
  padding-left: 10px;
}
#sendlist_page_container > div.auto {
  margin-left: 0;
}
#sendlist_page_container > div.auto label {
  display: none;
}
#sendlist_input_containers {
  margin-bottom: 5px;
  margin-top: 25px;
}
#page_comments h3 {
  margin-top: 30px;
}
#newsendage_dialog .add-single-climbs input {
  width: 92%;
  box-sizing: border-box;
}
#newsendage_dialog .add-single-climbs label {
  color: #666666;
  display: inline-block;
  font-style: italic;
  margin-bottom: 5px;
}
#newsendage_dialog .add-single-climbs p {
  margin-bottom: 5px;
}
#newsendage_dialog .add-single-climbs .errors {
  height: 24px;
  position: relative;
}
#newsendage_dialog .add-single-climbs .no-climb-found,
#newsendage_dialog .add-single-climbs .no-area-found,
#newsendage_dialog .add-single-climbs .climb-exists {
  color: #FF5C00;
  display: none;
  font-weight: bold;
  padding-top: 10px;
  position: absolute;
}
#newclimbdetails h3 {
  padding-top: 20px;
}
#karma {
  padding: 0 20px;
}
#karma.sidebar_panel {
  display: none;
  padding: 20px 10px;
  position: relative;
}
#karma.sidebar_panel.loading .content {
  background-image: url('/img/wait_spinner.gif');
  background-position: center center;
  background-repeat: no-repeat;
}
#karma.sidebar_panel .content {
  height: 20px;
}
#karma.sidebar_panel .content .question {
  margin-top: 0px;
  min-height: 0;
}
#karma.sidebar_panel .content .question h3 {
  font-size: 14px;
  padding-top: 0;
}
#karma.sidebar_panel .content .answer {
  min-height: 0;
}
#karma.sidebar_panel .actions {
  display: none;
  margin: 20px 0 0;
  min-height: 0;
  text-align: left;
}
#karma.sidebar_panel .actions button {
  margin-bottom: 10px;
}
#karma.sidebar_panel .beta {
  color: #666666;
  font-size: 11px;
  font-style: italic;
  padding-top: 20px;
}
#karma .question {
  margin-top: 30px;
  min-height: 60px;
}
#karma .answer {
  min-height: 200px;
}
#karma .answer form ul li {
  display: block;
  margin: 0;
  padding: 3px 0;
}
#karma .answer form ul li .fa {
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
}
#karma .answer form li.children {
  display: none;
  padding: 0 0 3px 25px;
}
#karma .actions {
  margin: 20px 0 50px;
  min-height: 50px;
  text-align: right;
}
#karma .actions .skip {
  color: #666666;
  cursor: pointer;
  padding-right: 20px;
}
#karma .actions .skip:hover {
  color: #1a1a1a;
}
#karma-leaderboard p {
  margin: 8px 0;
}
#karma-leaderboard table th,
#karma-leaderboard table td {
  padding: 5px 0;
}
#karma-leaderboard table th {
  color: #DF9300;
  font-weight: bold;
  width: 60px;
}
#karma-leaderboard table td {
  color: #666666;
}
#karma-leaderboard .sidebar_panel.img {
  background-position: center center;
  background-repeat: no-repeat;
}
#karma-leaderboard ol {
  padding-left: 20px;
  margin: 30px auto;
  max-width: 100%;
  width: 500px;
}
#karma-leaderboard ol li {
  border-bottom: 1px solid #666666;
  display: block;
  list-style: none;
  padding: 5px 0;
  position: relative;
  width: 100%;
}
#karma-leaderboard ol li:before {
  content: "";
  display: list-item;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  list-style: decimal;
  position: absolute;
}
#karma-leaderboard ol li > div {
  *zoom: 1;
  display: inline-block;
  width: 100%;
}
#karma-leaderboard ol li > div:before,
#karma-leaderboard ol li > div:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
#karma-leaderboard ol li > div:after {
  clear: both;
}
#karma-leaderboard ol li > div > div {
  box-sizing: border-box;
  float: left;
  height: 24px;
}
#karma-leaderboard ol li > div a {
  text-decoration: none;
}
#karma-leaderboard ol li > div .pic {
  width: 30px;
}
#karma-leaderboard ol li > div .name {
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  width: 70%;
}
#karma-leaderboard ol li > div .score {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-align: right;
  width: calc(30% - 30px);
}
.products_view .product {
  width: 100%;
}
.products_view .product .image,
.products_view .product .info {
  box-sizing: border-box;
}
.products_view .product .image {
  float: left;
  padding-right: 20px;
  width: 33.3333%;
}
.products_view .product .info {
  float: left;
  padding-top: 10px;
  width: 66.66667%;
}
.products_view .product .info .price {
  color: #FF5C00;
  font-weight: bold;
  font-size: 3em;
}
.products_view .product .info .shipping {
  color: #666666;
  margin-top: 5px;
}
.products_view .product .info .description {
  color: #666666;
  font-size: 1.25em;
  padding: 10px 0;
}
.products_view .product .info .buynow {
  margin-top: 10px;
}
.products_view .product .info ul.notes {
  color: #666666;
  padding: 20px 0 20px 10px;
}
.products_view .product .info ul.notes li {
  list-style-type: disc;
}
.comment_container {
  background-color: white;
  height: 100%;
  width: 100%;
}
.comment_container .content {
  background-color: black;
  float: left;
  height: 100%;
  text-align: center;
  width: 66.6666%;
}
.comment_container .content .image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
}
.comment_container .comments {
  height: 100%;
  float: right;
  overflow-y: scroll;
  padding: 20px;
  width: 33.3333%;
  box-sizing: border-box;
}
.comment_container .comments .meta {
  margin-bottom: 40px;
}
.comment_container .comments .meta h3 {
  color: black;
  float: left;
  padding: 0;
}
.comment_container .comments .meta > a {
  display: block;
  float: right;
  line-height: 20px;
}
#cboxOverlay {
  background-color: rgba(0, 0, 0, 0.8);
}
.media_view > .content {
  text-align: center;
}
.media_view > .content img {
  max-width: 100%;
}
/******* 960.gs overrides **********/
/********* tour *******/
#user_panel.jTour_exposed {
  position: fixed !important;
}
/************ print ***************/
.list-print-preview {
  color: #DF9300;
  font-weight: bold;
  height: 25px;
  line-height: 30px;
}
.print ul.ui-sendlist,
.print ul.ui-sendlist li {
  padding: 0;
}
.print h1 {
  color: black;
}
.print .checkbox {
  border: 1px solid #444;
  border-radius: 5px;
  font-size: 18px;
  height: 18px;
  line-height: 18px;
  margin: 2px 0 2px 0;
  text-align: center;
  width: 18px;
}
.print.sendlist .description {
  color: black;
  font-size: 12px;
  text-align: justify;
}
.print .ui-sendlist-climb-label {
  display: block;
  font-size: 11px;
}
.print sup {
  font-size: 10px;
}
.print .page-break {
  display: block;
  page-break-before: always;
}
.toggle-to-sendlist {
  color: #9a9a9a;
  cursor: pointer;
  display: none;
  line-height: 18px;
}
body.editing-sendlist .toggle-to-sendlist {
  display: inline;
}
body.editing-sendlist.viewing-editing-sendlist .toggle-to-sendlist {
  display: none;
}
.toggle-to-sendlist.added {
  color: #FF5C00;
}
#search2 {
  min-height: 677px;
  /* TODO
	FIX THIS CSS MESS. Trying to
	**/
}
#search2 .tab-container {
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;
}
#search2 .tabs {
  *zoom: 1;
  border-bottom: 2px solid #FF5C00;
  margin-bottom: 20px;
  width: 100%;
}
#search2 .tabs:before,
#search2 .tabs:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
#search2 .tabs:after {
  clear: both;
}
#search2 .tabs .tab {
  border: 1px solid #DF9300;
  border-bottom: none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  color: #666666;
  cursor: pointer;
  float: left;
  padding: 3px 8px;
}
#search2 .tabs .tab label {
  cursor: pointer;
}
#search2 .tabs .tab:hover {
  background-color: #DF9300;
  border-color: #DF9300;
  color: white;
}
#search2 .tabs .tab.active {
  background-color: #FF5C00;
  border-color: #FF5C00;
  color: white;
}
#search2 .tab-data {
  display: none;
}
#search2 .tab-data.active {
  display: block;
}
#search2.with-actions .dataset > div > div.climb {
  width: calc(40% - 35px);
}
#search2.with-actions .dataset > div > div.actions {
  display: block;
  width: 35px;
}
#search2.with-actions .actions {
  *zoom: 1;
}
#search2.with-actions .actions:before,
#search2.with-actions .actions:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
#search2.with-actions .actions:after {
  clear: both;
}
#search2.with-actions .actions .toggle-to-sendlist {
  float: right;
}
#search2 #filters * {
  box-sizing: border-box;
}
#search2 #filters dl {
  *zoom: 1;
}
#search2 #filters dl:before,
#search2 #filters dl:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
#search2 #filters dl:after {
  clear: both;
}
#search2 #filters dl dt {
  clear: left;
  float: left;
  line-height: 2em;
  padding-right: 5px;
  text-align: right;
  width: 27%;
}
#search2 #filters dl dd {
  float: left;
  padding-left: 5px;
  width: 73%;
}
#search2 #filters dl dd input + label {
  color: #666666;
  font-size: 11px;
  padding-left: 5px;
}
#search2 #filters dl dt,
#search2 #filters dl dd {
  margin-bottom: 10px;
}
#search2 #filters input[type=text] {
  width: 100%;
}
#search2 #filters .ui-slider {
  margin: 7px auto 0;
  width: 94%;
}
#search2 #filters .min_sends {
  *zoom: 1;
}
#search2 #filters .min_sends:before,
#search2 #filters .min_sends:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
#search2 #filters .min_sends:after {
  clear: both;
}
#search2 #filters .min_sends .slider_container,
#search2 #filters .min_sends .count {
  float: left;
}
#search2 #filters .min_sends .slider_container {
  padding: 6px 8px 0;
  width: 90%;
}
#search2 #filters .min_sends .count {
  line-height: 2em;
  text-align: right;
  width: 10%;
}
#search2 #filters .grade_range {
  *zoom: 1;
}
#search2 #filters .grade_range:before,
#search2 #filters .grade_range:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
#search2 #filters .grade_range:after {
  clear: both;
}
#search2 #filters .grade_range .slider_container,
#search2 #filters .grade_range .gmin,
#search2 #filters .grade_range .gmax {
  float: left;
}
#search2 #filters .grade_range .slider_container {
  padding: 6px 12px 0;
  width: 68%;
}
#search2 #filters .grade_range .gmin,
#search2 #filters .grade_range .gmax {
  line-height: 2em;
  width: 16%;
}
#search2 #filters .grade_range .gmin {
  text-align: right;
}
#search2 #filters #min_rating a {
  text-decoration: none;
  color: #aaa;
  font-size: 16px;
  line-height: 1.6em;
  padding: 0 1px;
}
#search2 #filters #min_rating a.selected {
  color: #FF5C00;
}
#search2 #filters #min_rating a.hover {
  color: #DF9300;
}
#search2 #filters #area_selections li {
  background-color: #cecece;
  border-radius: 4px;
  cursor: pointer;
  font-size: 11px;
  padding: 5px 55px 5px 5px;
  position: relative;
}
#search2 #filters #area_selections li:after {
  color: rgba(204, 0, 0, 0.5);
  content: 'remove';
  display: block;
  padding: 5px;
  position: absolute;
  right: 0;
  top: 0;
}
#search2 #filters #area_selections li:hover {
  background-color: #bbb;
}
#search2 #filters #area_selections li:hover:after {
  color: #cc0000;
}
#search2 #filters #reset_filters {
  display: block;
  margin-top: 20px;
  text-align: center;
}
#search2 .dataset {
  width: 100%;
}
#search2 .dataset > div {
  *zoom: 1;
}
#search2 .dataset > div:before,
#search2 .dataset > div:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
#search2 .dataset > div:after {
  clear: both;
}
#search2 .dataset > div > div {
  float: left;
  padding: 5px 0;
  box-sizing: border-box;
}
#search2 .dataset > div > div.climb {
  padding-right: 10px;
  width: 40%;
}
#search2 .dataset > div > div.grade,
#search2 .dataset > div > div.sends,
#search2 .dataset > div > div.rating {
  text-align: center;
  width: 20%;
}
#search2 .dataset > div > div.actions {
  display: none;
}
#search2 .dataset.header {
  border-bottom: 1px solid #bbb;
  margin-bottom: 10px;
}
#search2 .dataset.header .climb a {
  padding-left: 0;
}
#search2 .dataset.header a {
  color: #FF5C00;
  font-weight: bold;
  padding: 0 13px;
  position: relative;
  text-decoration: none;
}
#search2 .dataset.header a.asc:after {
  border-left: 5px solid transparent;
  border-top: 5px solid #FF5C00;
  border-right: 5px solid transparent;
  content: '';
  position: absolute;
  transform: scale(1.001);
  border-top: none;
  border-bottom: 5px solid #FF5C00;
  right: 0;
  top: 5px;
}
#search2 .dataset.header a.desc:after {
  border-left: 5px solid transparent;
  border-top: 5px solid #FF5C00;
  border-right: 5px solid transparent;
  content: '';
  position: absolute;
  transform: scale(1.001);
  right: 0;
  top: 5px;
}
#search2 .dataset > div > div.climb a {
  font-weight: bold;
}
#search2 .dataset > div > div.climb div,
#search2 .dataset > div > div.climb div > a {
  color: #DF9300;
  font-style: italic;
  font-weight: normal;
}
#search2 .num_results.full {
  font-weight: bold;
  line-height: 12px;
  padding: 0 0px 10px 0;
  text-align: right;
}
#search2 .num_results.full i.fa {
  font-size: 14px;
  line-height: 10px;
  margin-right: 10px;
}
#search2 #result_footer {
  display: none;
}
#search2 #pager,
#search2 #prevnext {
  margin-top: 20px;
}
#search2 #pager a,
#search2 #prevnext a {
  display: inline-block;
  font-weight: bold;
  margin: 0 5px;
  text-decoration: none;
}
#search2 #pager a.selected,
#search2 #prevnext a.selected {
  color: #DF9300;
  cursor: default;
}
#search2 #pager a.ignore,
#search2 #prevnext a.ignore {
  cursor: default;
}
#search2 #pager {
  float: left;
}
#search2 #prevnext {
  float: right;
}
#search2 form.search {
  margin-bottom: 30px;
}
#search2 .list_results .list_result {
  margin-bottom: 20px;
}
#search2 .list_results .list_result a {
  color: #666666;
  font-weight: bold;
  text-decoration: none;
}
#search2 #user_results h3 {
  color: #FF5C00;
  font-size: 14px;
  margin-bottom: 15px;
}
#search2 #user_results .user {
  margin-bottom: 20px;
}
#search2 #user_results .user .profilepic {
  float: left;
  margin-right: 5px;
}
#search2 #user_results .user a {
  color: #666666;
  display: block;
  font-weight: bold;
  text-decoration: none;
}
#search2 .old-search {
  margin-top: 40px;
  text-align: center;
}
#search2 .old-search a {
  color: #666666;
}
#search2.area-results .num_results,
#search2.area-results .filter-toggle {
  float: right;
}
#search2.area-results .filter-toggle {
  font-weight: bold;
}
#search2.area-results .filter-toggle a {
  text-decoration: none;
}
#search2.area-results #filters {
  display: none;
  margin-bottom: 20px;
}
#search2.area-results #filters #reset_filters {
  margin-top: 0;
}
#search2.area-results .dataset > div > div.climb > div {
  display: none;
}
.fa-spin {
  animation: spin 1s infinite linear;
}
.trophy-bg {
  background-color: white;
  background-size: cover;
  display: inline-block;
  font-size: 200px;
  padding: 20px 143px;
}
.fa-trophy {
  color: #ededed;
}
.fa-trophy.project {
  color: rgba(162, 89, 44, 0.6);
}
.fa-trophy.redpoint {
  color: #A2592C;
}
.fa-trophy.flash {
  color: #B3B3B3;
}
.fa-trophy.onsight {
  color: #E89C04;
}
.fa-icon.repeat,
.fa-icon.project {
  color: #A2592C;
}
.fa-trophy.add-send {
  font-size: 1.5em;
}
.trophy.fa-stack {
  line-height: 1.35em;
}
.trophy.fa-stack .fa-trophy + .fa-icon {
  font-size: 0.85em;
}
.trophy.fa-stack .fa-icon,
.trophy.fa-stack .fa-trophy {
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}
.trophy.fa-stack .fa-star {
  display: none;
}
.trophy.fa-stack.first-ascent .fa-star {
  display: block;
}
.trophy.fa-stack.first-ascent .fa-check-circle {
  display: none;
}
.trophy.fa-stack.first-ascent.redpoint .fa-icon,
.trophy.fa-stack.first-ascent.flash .fa-icon,
.trophy.fa-stack.first-ascent.onsight .fa-icon {
  color: #E1D70B;
}
.trophy.fa-stack.first-ascent.project .fa-star {
  display: none;
}
.trophy.fa-stack.project .fa-trophy {
  color: rgba(162, 89, 44, 0.5);
}
.trophy.fa-stack.project .fa-icon {
  color: rgba(122, 42, 33, 0.5);
}
.trophy.fa-stack.redpoint .fa-trophy {
  color: #A2592C;
}
.trophy.fa-stack.redpoint .fa-icon {
  color: #7A2A21;
  text-shadow: 0px 1px 2px rgba(51, 51, 51, 0.1);
}
.trophy.fa-stack.redpoint label {
  background-color: #7a4321;
  color: #c96f38;
}
.trophy.fa-stack.flash .fa-trophy {
  color: #9F9F9F;
}
.trophy.fa-stack.flash .fa-icon {
  color: #726969;
}
.trophy.fa-stack.flash label {
  background-color: #868686;
  color: #393939;
}
.trophy.fa-stack.onsight .fa-trophy {
  color: #E89C04;
}
.trophy.fa-stack.onsight .fa-icon {
  color: #9D5A03;
}
.trophy.fa-stack.onsight label {
  background-color: #b67a03;
  color: #fcc556;
}
.trophy.fa-stack label {
  border-radius: 3px;
  bottom: 1.7em;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.7);
  display: block;
  font-size: 0.125em;
  left: 50%;
  line-height: normal;
  margin: 0px auto 0px -17%;
  padding: 0.2em 0 0.1em;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 34%;
}
.fa-stack.add-to-list {
  cursor: pointer;
  font-size: 1.2em;
  height: 1em;
  line-height: 1em;
  width: 1em;
}
.fa-stack.add-to-list .fa-list-ul {
  color: #DF9300;
}
.fa-stack.add-to-list .fa-plus {
  color: #FF5C00;
  font-size: 0.8em;
  left: auto;
  right: -3px;
  text-align: right;
  top: -3px;
}
.noUi-target * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -ms-touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: default;
}
.noUi-base {
  width: 100%;
  height: 40px;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  border: 1px solid #BFBFBF;
  z-index: 1;
}
.noUi-handle {
  background: #EEE;
  height: 44px;
  width: 44px;
  border: 1px solid #BFBFBF;
  margin: -3px 0 0 -23px;
}
.noUi-active {
  background: #E9E9E9;
}
.noUi-active:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 100%;
  border: 1px solid #DDD;
}
.noUi-connect {
  background: Teal;
}
.noUi-background {
  background: #DDD;
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  border-radius: inherit;
}
.noUi-origin-upper {
  background: inherit !important;
}
.noUi-z-index {
  z-index: 10;
}
.noUi-vertical {
  width: 40px;
  height: 100%;
}
.noUi-vertical .noUi-origin {
  bottom: 0;
  left: 0;
}
.noUi-vertical .noUi-handle {
  margin: -23px 0 0 -3px;
}
.noUi-target[disabled] .noUi-base {
  background: #999;
}
.noUi-target[disabled] .noUi-connect {
  background: #BBB;
}
.noUi-state-tap .noUi-origin {
  -webkit-transition: left .3s,top .3s;
  transition: left 0.3s, top 0.3s;
}
.noUi-base {
  background-color: #cecece;
  height: 12px;
}
.noUi-handle {
  background-color: #ffb72d;
  border: 1px solid #c68200;
  border-radius: 3px;
  cursor: pointer;
  height: 16px;
  margin-left: -8px;
  width: 16px;
}
.fa-star-rating {
  color: #aaa;
  font-size: 16px;
}
.fa-star-rating .on {
  color: #DF9300;
}
.add-8a-climbs ul {
  color: #666666;
  padding-left: 15px;
}
.add-8a-climbs ul li {
  list-style-type: disc;
}
#sendlist-editing-panel {
  background-color: #EEE;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 1px 5px #222;
  box-sizing: border-box;
  color: #FF5C00;
  font-size: 18px;
  height: 32px;
  left: 50%;
  line-height: 32px;
  margin-left: -490px;
  padding: 0px 10px;
  position: fixed;
  top: 0;
  width: 980px;
  z-index: 999999;
}
.new-list-clicked #sendlist-editing-panel {
  left: 0%;
  margin-left: -980px;
  transition: all 0.4s cubic-bezier(1, 0.095, 0.545, 1.015);
}
#sendlist-editing-panel.just-added {
  left: 50%;
  margin-left: -490px;
}
#sendlist-editing-panel i,
#sendlist-editing-panel a,
#sendlist-editing-panel span {
  display: inline-block;
  padding: 0 2px;
}
#sendlist-editing-panel a.link-to {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 440px) {
  #sendlist-editing-panel a.link-to {
    max-width: 225px;
  }
}
@media screen and (max-width: 350px) {
  #sendlist-editing-panel a.link-to {
    max-width: 175px;
  }
}
#sendlist-editing-panel .status {
  float: left;
  margin-right: 10px;
}
#sendlist-editing-panel .actions {
  float: right;
}
#sendlist-editing-panel .actions i,
#sendlist-editing-panel .actions a {
  cursor: pointer;
  padding: 0 5px;
  text-decoration: none;
}
#sendlist-editing-panel .actions i.unavailable,
#sendlist-editing-panel .actions a.unavailable {
  display: none;
}
@media screen and (max-width: 980px) {
  #sendlist-editing-panel {
    border-left: none;
    border-right: none;
    border-radius: 0;
    left: 0;
    margin-left: 0;
    width: 100%;
  }
  .new-list-clicked #sendlist-editing-panel {
    left: -100%;
    margin-left: 0;
    transition: all 0.4s cubic-bezier(1, 0.095, 0.545, 1.015);
  }
  #sendlist-editing-panel.just-added {
    left: 0;
    margin-left: 0;
  }
}
/* END DESKTOP CSS */
/************************************************************************************************/
#header {
  padding: 5px 0 10px 0;
}
#header * {
  box-sizing: border-box;
  word-wrap: break-word;
}
#header .logopane {
  height: auto;
  width: auto;
}
#header .logopane .boulders,
#header .logopane .banner {
  display: none;
}
#header .logopane .navigation {
  background: none !important;
  height: auto;
  left: auto;
  margin-left: 0;
  position: relative;
  width: auto;
}
#header .logopane .navigation a {
  color: #DF9300;
  display: inline-block;
  height: auto !important;
  font-size: 1.4em;
  left: auto !important;
  line-height: 1.6em;
  padding: 0 8px;
  position: relative;
  text-indent: 0;
  text-decoration: none;
  top: auto !important;
  width: auto !important;
}
#header .logopane .navigation a:hover {
  background: none !important;
}
#header .logopane .navigation a.home {
  background-image: url('/img/SendageTextLogo.png') !important;
  background-position: 0 0 !important;
  text-indent: -9999px;
  display: block;
  margin-left: 10px;
  margin-bottom: 10px;
  height: 30px !important;
  width: 165px !important;
}
#header .logopane .navigation a.create .badge {
  background-color: #FF5C00;
  border-radius: 8px;
  color: #fff;
  display: inline-block;
  font-family: monospace;
  font-size: 12px;
  line-height: 18px;
  margin-left: 6px;
  padding: 0 5px;
  position: relative;
  text-align: center;
}
#header .social {
  bottom: auto;
  padding-right: 2px;
  right: 5px;
  top: 18px;
}
#header .social a {
  font-size: 1.75em;
  margin-left: 8px;
}
#user_panel {
  display: none;
}
#stickyfooter-wrapper {
  background: none;
  background-color: black;
}
@media screen and (max-width: 799px) {
  html {
    -webkit-text-size-adjust: none;
  }
}
@media screen and (max-width: 699px) {
  body.mobile-device {
    min-width: inherit;
  }
  body.mobile-device * {
    box-sizing: border-box;
    word-wrap: break-word;
  }
  body.mobile-device .dev-banner {
    display: none;
  }
  body.mobile-device #stickyfooter-wrapper {
    background: none;
    background-color: black;
    width: 100%;
  }
  body.mobile-device .container_12 .grid_1,
  body.mobile-device .container_12 .grid_2,
  body.mobile-device .container_12 .grid_3,
  body.mobile-device .container_12 .grid_4,
  body.mobile-device .container_12 .grid_5,
  body.mobile-device .container_12 .grid_6,
  body.mobile-device .container_12 .grid_7,
  body.mobile-device .container_12 .grid_8,
  body.mobile-device .container_12 .grid_9,
  body.mobile-device .container_12 .grid_10,
  body.mobile-device .container_12 .grid_11,
  body.mobile-device .container_12 .grid_12 {
    width: auto;
  }
  body.mobile-device #centerpane2 {
    border-left: none;
    border-right: none;
    border-radius: 0;
    width: auto;
  }
  body.mobile-device #centerpane2 > .grid_4.pages_home {
    display: none;
  }
  body.mobile-device #centerpane,
  body.mobile-device #footer {
    width: auto;
  }
  body.mobile-device .container_12 {
    width: auto;
  }
  body.mobile-device .container_12 .grid_8 {
    clear: both;
    float: none;
    display: block;
    width: auto;
  }
  body.mobile-device ul.home-feed li > .col {
    margin-right: 0;
    width: 15%;
  }
  body.mobile-device ul.home-feed li > .colLast {
    width: 85%;
  }
  body.mobile-device ul.home-feed li > .colLast .feed-send-title {
    float: none;
    margin-right: 0;
  }
  body.mobile-device ul.home-feed li > .colLast .feed-send-title .feed-send-description {
    margin-top: 25px;
  }
  body.mobile-device .feed-list-description {
    width: auto;
  }
  body.mobile-device .feed-send-description {
    font-size: 13px;
    width: auto;
  }
  body.mobile-device #stickyfooter-wrapper {
    margin: 0 auto -90px;
  }
  body.mobile-device #stickyfooter-push,
  body.mobile-device #footer {
    height: 90px;
  }
  body.mobile-device #footer .links {
    line-height: 1.5em;
    text-align: left;
  }
  body.mobile-device #footer .footer-link {
    margin: 0 10px 0 0;
    padding: 0;
  }
  body.mobile-device #footer .footer-link.bug,
  body.mobile-device #footer .footer-link.feature,
  body.mobile-device #footer .footer-link.review {
    display: none;
  }
  body.mobile-device #footer .footer-link:after {
    content: '';
  }
  body.mobile-device #footer .footer-link a {
    margin: 0;
    padding: 0;
  }
  body.mobile-device .search_faq {
    display: none;
  }
  body.mobile-device sub {
    font-size: inherit;
  }
  body.mobile-device .container_12 .grid_6 {
    display: block;
    float: none;
    width: auto;
  }
  body.mobile-device .sendlist_create #sendlist_name,
  body.mobile-device .sendlist_create #sendlist_description {
    width: 100%;
  }
  body.mobile-device #sendlist_info {
    width: 100%;
  }
  body.mobile-device #sendlist_edit_links {
    width: 100%;
    text-align: left;
  }
  body.mobile-device .sendlists_view .progress,
  body.mobile-device .sendlists_view .like_container {
    display: none;
  }
  body.mobile-device .users_view .user-details {
    float: none;
    margin-right: 0;
  }
  body.mobile-device .users_view .user-sponsors {
    margin-right: 0;
    padding-right: 10px;
  }
  body.mobile-device .users_view .profilepic-col {
    display: none;
  }
  body.mobile-device .users_view .profile-info {
    float: none;
    margin-right: 0;
    width: 100%;
  }
  body.mobile-device .users_view .profile-bio {
    padding-left: 0;
    width: auto;
  }
  body.mobile-device .users_view .profile-bio.loggedin {
    width: auto;
  }
  body.mobile-device .users_view .logged_in_buttons {
    float: left;
    margin: 0;
  }
  body.mobile-device .users_view #sendage_media_container {
    display: none;
  }
  body.mobile-device .users_view .logged_in_buttons {
    padding-top: 10px;
  }
  body.mobile-device .users_view .logged_in_buttons div {
    display: inline-block;
  }
  body.mobile-device .users_view .logged_in_buttons #show_media_panel_btn,
  body.mobile-device .users_view .logged_in_buttons #widget_builder_btn {
    display: none;
  }
  body.mobile-device .users_view #show_sendlists_btn {
    display: inline-block;
  }
  body.mobile-device .users_view #sendlist_input_containers .colRight #sendlist_search_container {
    display: none;
  }
  body.mobile-device .users_view.sidebar {
    display: none;
    float: none;
    margin: 10px;
    width: auto;
  }
  body.mobile-device .users_view.sidebar .sidebar_panel {
    width: auto;
  }
  body.mobile-device .climbs_view {
    /* sidebar */
  }
  body.mobile-device .climbs_view.grid_4 {
    display: none;
  }
  body.mobile-device .climbs_view.grid_8 .climb-info .col {
    width: auto !important;
    margin-bottom: 5px;
    margin-right: 0;
  }
  body.mobile-device .climbs_view.grid_8 .climb-info h1 {
    padding-bottom: 0;
    padding-right: 10px;
  }
  body.mobile-device .climbs_view.grid_8 .climb-info .climb-rating {
    clear: both;
    float: left;
    margin-bottom: 10px;
  }
  body.mobile-device .climbs_view.grid_8 .climb-info .colRight {
    float: left;
    margin-left: 0;
  }
  body.mobile-device .climbs_view.grid_8 .climb-info .colRight .fb-like {
    display: none;
  }
  body.mobile-device .climbs_view.grid_8 ul.climb-beta li .avatar {
    display: none;
  }
  body.mobile-device .climbs_view.grid_8 ul.climb-beta li .comments {
    margin-right: 0;
    width: auto;
  }
  body.mobile-device .climbs_view.grid_8 ul.recent-sendage li > .col {
    margin-right: 0;
    padding-right: 10px;
  }
  body.mobile-device .climbs_view.grid_8 ul.recent-sendage li > .trophy {
    width: 10%;
  }
  body.mobile-device .climbs_view.grid_8 ul.recent-sendage li > .avatar {
    display: none;
  }
  body.mobile-device .climbs_view.grid_8 ul.recent-sendage li > .title {
    width: 30%;
  }
  body.mobile-device .climbs_view.grid_8 ul.recent-sendage li > .comments {
    padding-right: 0;
    width: 60%;
  }
  body.mobile-device .user_climbs_view.grid_8 .title {
    width: 44%;
  }
  body.mobile-device .user_climbs_view .trophy-count {
    width: 74px;
  }
  body.mobile-device .user_climbs_view.grid_4 {
    display: none;
  }
  body.mobile-device .areas_index .grid_12,
  body.mobile-device .areas_index .grid_3 {
    display: block;
    float: none;
  }
  body.mobile-device .areas_view .title {
    width: auto;
  }
  body.mobile-device .areas_view .fb-like {
    display: none;
  }
  body.mobile-device .areas_view.sidebar {
    display: block;
    float: none;
    margin: 20px 10px;
    width: auto;
  }
  body.mobile-device .areas_view.sidebar .sidebar_panel {
    width: auto !important;
  }
  body.mobile-device .areas_view .search-filters > .col {
    margin-right: 0;
    width: 40%;
  }
  body.mobile-device .areas_view .search-filters > .colRight {
    margin-left: 0;
  }
  body.mobile-device .areas_view .search-filters #result_type {
    width: 100%;
  }
  body.mobile-device ul.ui-sendlist {
    padding: 0;
  }
  body.mobile-device ul.ui-sendlist li div.sendlist-action-buttons .ui-sendlist-makecomment {
    display: none;
  }
  body.mobile-device ul.ui-sendlist li div.rating {
    float: left;
  }
  body.mobile-device ul.ui-sendlist li div.rating .fa-star-rating {
    font-size: 10px;
  }
  body.mobile-device ul.ui-sendlist li .col,
  body.mobile-device ul.ui-sendlist li .colRight {
    margin: 0;
  }
  body.mobile-device ul.ui-sendlist li .col {
    padding-right: 8px;
  }
  body.mobile-device ul.ui-sendlist li > .trophy.col {
    width: 40px;
  }
  body.mobile-device ul.ui-sendlist li > .info.col {
    width: calc(100% - 115px);
  }
  body.mobile-device ul.ui-sendlist li > .grade.col {
    width: 60px;
  }
  body.mobile-device ul.ui-sendlist li > .sendlist-action-buttons.colRight {
    margin-top: 0;
    text-align: right;
    width: 15px;
  }
  body.mobile-device ul.ui-sendlist li > .sendlist-action-buttons.colRight > div,
  body.mobile-device ul.ui-sendlist li > .sendlist-action-buttons.colRight > button {
    clear: right;
    float: right;
  }
  body.mobile-device ul.ui-sendlist li > .comments.col {
    clear: both;
    padding: 5px 0 0 40px;
    width: 100%;
  }
  body.mobile-device ul.ui-sendlist li sub {
    font-size: 11px;
  }
  body.mobile-device .ui-dialog {
    left: 0 !important;
    width: 100% !important;
  }
  body.mobile-device #newsendage_dialog .add-single-climbs .col,
  body.mobile-device #newsendage_dialog .add-single-climbs .colRight {
    display: block;
    float: none;
    margin: 0;
    width: auto;
  }
  body.mobile-device #newsendage_dialog .add-single-climbs .col input,
  body.mobile-device #newsendage_dialog .add-single-climbs .colRight input {
    width: 80%;
  }
  body.mobile-device #sendage-dialog #sendage-dialog-datepicker {
    width: 70%;
  }
  body.mobile-device #sendage-dialog #sendlist_media_uploader {
    display: none;
  }
  body.mobile-device #sendage-dialog .rating-container {
    margin-bottom: 15px;
  }
  body.mobile-device #sendage-dialog .grade-picker {
    clear: both;
  }
  body.mobile-device #sendage-dialog .nonproject.last.colRight {
    float: left;
    padding-bottom: 10px;
  }
  body.mobile-device #sendage-dialog .sendage-dialog-beta,
  body.mobile-device #sendage-dialog .sendage-dialog-comment {
    width: 100%;
  }
  body.mobile-device #sendage-dialog .trophy-selector .trophy-count {
    width: 60px;
  }
  body.mobile-device #sendage-dialog .trophy-selector .minor {
    display: block;
    margin-top: 15px;
  }
  body.mobile-device #sendage-dialog .day > .col {
    float: none;
  }
  body.mobile-device #searchResults .col {
    padding-right: 10px;
    margin: 0;
  }
  body.mobile-device #searchResults .climb {
    width: 41%;
  }
  body.mobile-device #searchResults .grade {
    padding-right: 2px;
    width: 10%;
  }
  body.mobile-device #searchResults .sends {
    padding-right: 3px;
    width: 7%;
  }
  body.mobile-device #searchResults .sends-label {
    width: 11%;
  }
  body.mobile-device #searchResults .rating {
    margin-left: 0;
    width: 31%;
  }
  body.mobile-device #searchResults .rating > div {
    float: right;
    width: 85px;
  }
  body.mobile-device .products_view .product .image,
  body.mobile-device .products_view .product .info {
    width: 100%;
  }
  body.mobile-device .comment_container .content {
    width: 100%;
  }
  body.mobile-device .comment_container .comments {
    display: none;
  }
  body.mobile-device #search2 #filters #reset_filters {
    margin: 10px 0;
  }
  body.mobile-device #search2 .old-search {
    margin: 10px 0 40px;
  }
  body.mobile-device .areas_view .area_children .group {
    padding: 0 10px;
    width: 100%;
  }
}
@media screen and (max-width: 479px) {
  body.mobile-device ul.home-feed li > .col {
    width: 20%;
  }
  body.mobile-device ul.home-feed li > .colLast {
    width: 80%;
  }
  body.mobile-device .climbs_view.grid_8 ul.recent-sendage li {
    position: relative;
  }
  body.mobile-device .climbs_view.grid_8 ul.recent-sendage li > .trophy {
    width: 50px;
  }
  body.mobile-device .climbs_view.grid_8 ul.recent-sendage li > .trophy .fa-stack {
    font-size: 14px;
  }
  body.mobile-device .climbs_view.grid_8 ul.recent-sendage li > .title {
    width: 100px;
  }
  body.mobile-device .climbs_view.grid_8 ul.recent-sendage li > .comments {
    width: calc(100% - 150px);
  }
  body.mobile-device .climbs_view.grid_8 ul.recent-sendage li .prompt {
    font-size: 10px;
  }
  body.mobile-device #search2 .dataset > div > div {
    font-size: 10px;
  }
  body.mobile-device #search2 .dataset > div > div.climb {
    width: calc(100% - 180px);
  }
  body.mobile-device #search2 .dataset > div > div.grade {
    width: 60px;
  }
  body.mobile-device #search2 .dataset > div > div.sends {
    width: 60px;
  }
  body.mobile-device #search2 .dataset > div > div.rating {
    width: 60px;
  }
  body.mobile-device #search2 .dataset > div > div.actions {
    width: 28px;
  }
  body.mobile-device #search2.with-actions .dataset > div > div.climb {
    width: calc(100% - 208px);
  }
  body.mobile-device #search2 .dataset > div > div.rating {
    font-size: 10px;
  }
}
